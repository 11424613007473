import { uploadFileBlood } from 'app/modules/utils/UploadImage';
import * as requestFromServer from './groupCrud';
import { groupSlice, callTypes } from './groupSlice';

const { actions } = groupSlice;

export const fetchGroups = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getAllGroups(queryParams)
    .then(response => {
      dispatch(actions.groupsFetched(response));
    })
    .catch(error => {
      error.clientMessage = "Can't find group";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchGroupsUnread = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getAllGroups(queryParams)
    .then(response => {
      dispatch(actions.groupsUnreadFetched(response));
    })
    .catch(error => {
      error.clientMessage = "Can't find group";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchGroupGroups = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getAllGroupGroups(queryParams)
    .then(response => {
      dispatch(actions.endCall());
      return response;
    })
    .catch(error => {
      error.clientMessage = "Can't find group";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchGroupsPreview = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getAllGroups(queryParams)
    .then(response => {
      const { totalCount, entities } = response.data;
      dispatch(actions.groupsPreviewFetched({ totalCount, entities }));
    })
    .catch(error => {
      error.clientMessage = "Can't find group";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchGroup = id => dispatch => {
  if (!id) {
    return dispatch(actions.groupFetched({ groupForEdit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getGroupById(id)
    .then(response => {
      dispatch(actions.groupFetched({ groupForEdit: response }));
    })
    .catch(error => {
      error.clientMessage = "Can't find group";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteGroup = id => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteGroup(id)
    .then(response => {
      dispatch(actions.endCall());
    })
    .catch(error => {
      error.clientMessage = "Can't delete group";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createGroup = groupForCreation => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createGroup(groupForCreation)
    .then(response => {
      dispatch(actions.endCall());
    })
    .catch(error => {
      error.clientMessage = "Can't create group";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
export const uploadImage = params => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return uploadFileBlood(params);
};

export const updateGroup = group => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateGroup(group)
    .then(() => {
      dispatch(actions.groupUpdated({ group }));
    })
    .catch(error => {
      error.clientMessage = "Can't update group";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
