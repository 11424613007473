import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { parseErrorToHandle } from 'app/modules/utils/requestUtils';
import {
  getCommentStatisticsAPI,
  getContentStatisticsAPI,
  getDashboardInfoAPI,
  getInspectionStatisticsAPI,
  getMemberStatisticsByAgeAPI,
  getMemberStatisticsByGenderAPI,
  getMemberStatisticsByOSAPI,
  getTopCreatorAPI,
  getVideoAccumulationAPI,
  getVideoPopularAPI,
  getVideoTopCommentAPI,
  getVideoTopLikeAPI,
  getVideoTopReviewAPI,
  getVideoTopShareAPI,
  getVideoTopViewAPI,
  getTopTagAPI
} from './dashboardCrud';

const initialDashboardState = {
  dashboardInfo: {}
};

export const getDashboardInfo = createAsyncThunk(
  'dashboard/getDashboardInfo',
  async (params, { rejectWithValue }) => {
    try {
      const memberStatistics = await getDashboardInfoAPI(params);
      return { memberStatistics };
    } catch (error) {
      return rejectWithValue(parseErrorToHandle(error));
    }
  }
);

export const getTopTag = createAsyncThunk(
  'dashboard/getTopTag',
  async (params, { rejectWithValue }) => {
    try {
      const videoTopTags = await getTopTagAPI(params);
      return { videoTopTags };
    } catch (error) {
      return rejectWithValue(parseErrorToHandle(error));
    }
  }
);
export const getInspectionStatistics = createAsyncThunk(
  'dashboard/getInspectionStatistics',
  async (params, { rejectWithValue }) => {
    try {
      const data = await getInspectionStatisticsAPI(params);
      return data;
    } catch (error) {
      return rejectWithValue(parseErrorToHandle(error));
    }
  }
);

export const getContentStatistics = createAsyncThunk(
  'dashboard/getContentStatistics',
  async (params, { rejectWithValue }) => {
    try {
      const data = await getContentStatisticsAPI(params);
      return data;
    } catch (error) {
      return rejectWithValue(parseErrorToHandle(error));
    }
  }
);

export const getCommentStatistics = createAsyncThunk(
  'dashboard/getCommentStatistics',
  async (params, { rejectWithValue }) => {
    try {
      const data = await getCommentStatisticsAPI(params);
      return data;
    } catch (error) {
      return rejectWithValue(parseErrorToHandle(error));
    }
  }
);

export const getMemberStatisticsByAge = createAsyncThunk(
  'dashboard/getMemberStatisticsByAge',
  async (params, { rejectWithValue }) => {
    try {
      const data = await getMemberStatisticsByAgeAPI(params);
      return data;
    } catch (error) {
      return rejectWithValue(parseErrorToHandle(error));
    }
  }
);

export const getMemberStatisticsByGender = createAsyncThunk(
  'dashboard/getMemberStatisticsByGender',
  async (params, { rejectWithValue }) => {
    try {
      const data = await getMemberStatisticsByGenderAPI(params);
      return data;
    } catch (error) {
      return rejectWithValue(parseErrorToHandle(error));
    }
  }
);

export const getMemberStatisticsByOS = createAsyncThunk(
  'dashboard/getMemberStatisticsByOS',
  async (params, { rejectWithValue }) => {
    try {
      const data = await getMemberStatisticsByOSAPI(params);
      return data;
    } catch (error) {
      return rejectWithValue(parseErrorToHandle(error));
    }
  }
);

export const getVideoAccumulation = createAsyncThunk(
  'dashboard/getVideoAccumulation',
  async (params, { rejectWithValue }) => {
    try {
      const data = await getVideoAccumulationAPI(params);
      return data;
    } catch (error) {
      return rejectWithValue(parseErrorToHandle(error));
    }
  }
);

export const getTopCreator = createAsyncThunk(
  'dashboard/getTopCreator',
  async (params, { rejectWithValue }) => {
    try {
      const data = await getTopCreatorAPI(params);
      return data;
    } catch (error) {
      return rejectWithValue(parseErrorToHandle(error));
    }
  }
);

export const getVideoPopular = createAsyncThunk(
  'dashboard/getVideoPopular',
  async (params, { rejectWithValue }) => {
    try {
      const data = await getVideoPopularAPI(params);
      return data;
    } catch (error) {
      return rejectWithValue(parseErrorToHandle(error));
    }
  }
);

export const getVideoTopView = createAsyncThunk(
  'dashboard/getVideoTopView',
  async (params, { rejectWithValue }) => {
    try {
      const data = await getVideoTopViewAPI(params);
      return data;
    } catch (error) {
      return rejectWithValue(parseErrorToHandle(error));
    }
  }
);

export const getVideoTopLike = createAsyncThunk(
  'dashboard/getVideoTopLike',
  async (params, { rejectWithValue }) => {
    try {
      const data = await getVideoTopLikeAPI(params);
      return data;
    } catch (error) {
      return rejectWithValue(parseErrorToHandle(error));
    }
  }
);

export const getVideoTopComment = createAsyncThunk(
  'dashboard/getVideoTopComment',
  async (params, { rejectWithValue }) => {
    try {
      const data = await getVideoTopCommentAPI(params);
      return data;
    } catch (error) {
      return rejectWithValue(parseErrorToHandle(error));
    }
  }
);

export const getVideoTopReview = createAsyncThunk(
  'dashboard/getVideoTopReview',
  async (params, { rejectWithValue }) => {
    try {
      const data = await getVideoTopReviewAPI(params);
      return data;
    } catch (error) {
      return rejectWithValue(parseErrorToHandle(error));
    }
  }
);

export const getVideoTopShare = createAsyncThunk(
  'dashboard/getVideoTopShare',
  async (params, { rejectWithValue }) => {
    try {
      const data = await getVideoTopShareAPI(params);
      return data;
    } catch (error) {
      return rejectWithValue(parseErrorToHandle(error));
    }
  }
);

export const dashboardSlice = createSlice({
  name: 'Dashboard',
  initialState: initialDashboardState,
  reducers: {},
  extraReducers: {
    [getDashboardInfo.fulfilled]: (state, action) => {
      state.dashboardInfo = { ...state.dashboardInfo, ...action.payload };
    },

    [getInspectionStatistics.fulfilled]: (state, action) => {
      state.dashboardInfo = {
        ...state.dashboardInfo,
        inspectionStatistics: action.payload
      };
    },
    [getTopTag.fulfilled]: (state, action) => {
      state.dashboardInfo = { ...state.dashboardInfo, ...action.payload };
    },

    [getContentStatistics.fulfilled]: (state, action) => {
      state.dashboardInfo = {
        ...state.dashboardInfo,
        contentStatistics: action.payload
      };
    },

    [getCommentStatistics.fulfilled]: (state, action) => {
      state.dashboardInfo = {
        ...state.dashboardInfo,
        commentStatistics: action.payload
      };
    },

    [getMemberStatisticsByAge.fulfilled]: (state, action) => {
      state.dashboardInfo = {
        ...state.dashboardInfo,
        memberStatisticsByAge: action.payload
      };
    },

    [getMemberStatisticsByGender.fulfilled]: (state, action) => {
      state.dashboardInfo = {
        ...state.dashboardInfo,
        memberStatisticsByGender: action.payload
      };
    },

    [getMemberStatisticsByOS.fulfilled]: (state, action) => {
      state.dashboardInfo = {
        ...state.dashboardInfo,
        memberStatisticsByOS: action.payload
      };
    },

    [getVideoAccumulation.fulfilled]: (state, action) => {
      state.dashboardInfo = {
        ...state.dashboardInfo,
        videoAccumulation: action.payload
      };
    },

    [getTopCreator.fulfilled]: (state, action) => {
      state.dashboardInfo = {
        ...state.dashboardInfo,
        topCreator: action.payload
      };
    },

    [getVideoPopular.fulfilled]: (state, action) => {
      state.dashboardInfo = {
        ...state.dashboardInfo,
        videoPopular: action.payload
      };
    },

    [getVideoTopView.fulfilled]: (state, action) => {
      state.dashboardInfo = {
        ...state.dashboardInfo,
        videoTopView: action.payload
      };
    },

    [getVideoTopLike.fulfilled]: (state, action) => {
      state.dashboardInfo = {
        ...state.dashboardInfo,
        videoTopLike: action.payload
      };
    },

    [getVideoTopComment.fulfilled]: (state, action) => {
      state.dashboardInfo = {
        ...state.dashboardInfo,
        videoTopComment: action.payload
      };
    },

    [getVideoTopReview.fulfilled]: (state, action) => {
      state.dashboardInfo = {
        ...state.dashboardInfo,
        videoTopReview: action.payload
      };
    },

    [getVideoTopShare.fulfilled]: (state, action) => {
      state.dashboardInfo = {
        ...state.dashboardInfo,
        videoTopShare: action.payload
      };
    }
  }
});

export const selectDashboardInfo = state => state.dashboard.dashboardInfo;
