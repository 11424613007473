import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { parseErrorToHandle } from "app/modules/utils/requestUtils";
import {
  createReportCommentAPI,
  fetchHistoryCommentDetailAPI,
  fetchNumberReportDetailAPI,
  findCommentAPI,
  findCommentsAPI,
  findCommentsAPIExcel,
  updateSubCategoryAPI,
  pinCommentAPI,
  saveIngredientAPI,
} from "./commentCardCrud";
import { toastReturnAPI } from "app/modules/utils/helper";

const initialCommentState = {
  totalCount: 0,
  entities: [],
  numberReportDetail: [],
  historyCommentDetail: [],
  commentDetail: {},
  isSuccessChangeStatusComment: false,
};

export const findComments = createAsyncThunk(
  "comment/findComments",
  async (params, { rejectWithValue }) => {
    try {
      const data = await findCommentsAPI(params);
      return data;
    } catch (error) {
      return rejectWithValue(parseErrorToHandle(error));
    }
  }
);
export const saveIngredient = createAsyncThunk(
  "comment/saveIngredientAPI",
  async (body, { rejectWithValue }) => {
    try {
      const data = await saveIngredientAPI(body);
      return data;
    } catch (error) {
      return rejectWithValue(parseErrorToHandle(error));
    }
  }
);
export const pinComment = createAsyncThunk(
  "comment/pinComment",
  async (body, { rejectWithValue }) => {
    try {
      const data = await pinCommentAPI(body);
      return data;
    } catch (error) {
      return rejectWithValue(parseErrorToHandle(error));
    }
  }
);

export const clearCommentDetail = createAsyncThunk(
  "comment/clearCommentDetail",
  async (params, { rejectWithValue }) => {
    return "";
  }
);

export const findComment = createAsyncThunk(
  "comment/findComment",
  async (id, { rejectWithValue }) => {
    try {
      const data = await findCommentAPI(id);
      return data;
    } catch (error) {
      return rejectWithValue(parseErrorToHandle(error));
    }
  }
);

export const findCommentsExcel = createAsyncThunk(
  "comment/findCommentExcel",
  async (params, { rejectWithValue }) => {
    try {
      const data = await findCommentsAPIExcel(params);
      return data;
    } catch (error) {
      return rejectWithValue(parseErrorToHandle(error));
    }
  }
);

export const fetchNumberReportDetail = createAsyncThunk(
  "comment/fetchNumberReportDetail",
  async (params, { rejectWithValue }) => {
    try {
      const data = await fetchNumberReportDetailAPI(params);
      return data;
    } catch (error) {
      return rejectWithValue(parseErrorToHandle(error));
    }
  }
);

export const fetchHistoryCommentDetail = createAsyncThunk(
  "comment/fetchHistoryCommentDetail",
  async (params, { rejectWithValue }) => {
    try {
      const data = await fetchHistoryCommentDetailAPI(params);
      return data;
    } catch (error) {
      return rejectWithValue(parseErrorToHandle(error));
    }
  }
);

export const createReportComment = createAsyncThunk(
  "comment/createReportComment",
  async (params, { rejectWithValue }) => {
    try {
      const data = await createReportCommentAPI(params);
      return { data, message: "Change status report comment successfully" };
    } catch (error) {
      if (error?.messages?.[0]?.statusCode === 401) {
        toastReturnAPI("Change status report comment successfully");
        return 401;
      }
      return rejectWithValue(parseErrorToHandle(error));
    }
  }
);
export const updateSubCategory = createAsyncThunk(
  "comment/commentCategories",
  async (params, { rejectWithValue }) => {
    try {
      const { id, admin_user, code } = params;
      const body = { admin_update: new Date(), admin_user, code };
      const data = await updateSubCategoryAPI(body, id);
      return { data, message: "Change sub category successfully" };
    } catch (error) {
      return rejectWithValue(parseErrorToHandle(error));
    }
  }
);
export const commentSlice = createSlice({
  name: "Comment",
  initialState: initialCommentState,
  reducers: {},
  extraReducers: {
    [findComments.fulfilled]: (state, action) => {
      const { data, total } = action.payload;
      state.entities = data;
      state.totalCount = total;
    },
    [findComments.rejected]: (state, action) => {
      state.entities = [];
      state.totalCount = 0;
    },
    [findComment.fulfilled]: (state, action) => {
      state.commentDetail = action.payload?.data?.[0];
    },
    [clearCommentDetail.fulfilled]: (state, action) => {
      state.commentDetail = {};
    },
    [fetchNumberReportDetail.fulfilled]: (state, action) => {
      state.numberReportDetail = action.payload;
    },

    [fetchNumberReportDetail.rejected]: (state, action) => {
      state.numberReportDetail = [];
    },

    [fetchHistoryCommentDetail.fulfilled]: (state, action) => {
      const { data } = action.payload;
      state.historyCommentDetail = data;
    },
    [fetchHistoryCommentDetail.rejected]: (state, action) => {
      state.historyCommentDetail = [];
    },

    [createReportComment.fulfilled]: (state, action) => {
      state.isSuccessChangeStatusComment = true;
    },
    [createReportComment.rejected]: (state, action) => {
      state.isSuccessChangeStatusComment = false;
    },
  },
});
