import React from "react";

export function Checkbox({
  children,
  label,
  type = "checkbox",
  field,
  form,
  isSelected,
  className,
  onChangeCallback = null,
  dataChecked = false,
  isDisabled = false,
}) {
  const { name, value } = field;
  const onChange = (data) => {
    const valueChecked = dataChecked ? data.target.checked : value;
    form.setFieldValue(name, valueChecked);
    if (onChangeCallback) {
      onChangeCallback(valueChecked, name);
    }
  };

  return (
    <label className={`checkbox checkbox-lg checkbox-single ${className}`}>
      <input
        disabled={isDisabled}
        type={type}
        checked={isSelected}
        name={name}
        onChange={onChange}
      />
      {children}
      <span />

      <label
        title=""
        htmlFor="inline-checkbox-2"
        className="form-check-label mg-l-5 "
      >
        {label}
      </label>
    </label>
  );
}
