import ApiService from "./ApiService";

export const uploadFile = async (queryParams) => {
  let dataUpload = queryParams?.image;
  if (checkImageBase64(queryParams)) {
    const data = await fetchBase64(queryParams.image);

    return new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append("files", data);
      resolve(ApiService.upload(`/upload`, formData));
    });
  }

  return new Promise((resolve, reject) => {
    const formData = new FormData();
    formData.append("files", dataUpload);
    resolve(ApiService.upload(`/upload`, formData));
  });
};

export const uploadFormData = async (formData) => {
  return ApiService.upload(`/upload`, formData);
};

export const uploadFileBlood = async (file) => {
  return new Promise((resolve, reject) => {
    const formData = new FormData();
    formData.append("files", file);
    resolve(ApiService.upload(`/upload`, formData));
  });
};

const fetchBase64 = (base64) => {
  return new Promise((resolve) => {
    fetch(base64)
      .then((res) => res.blob())
      .then((blob) => {
        const file = new File([blob], "filename.jpeg");
        resolve(file);
      });
  });
};
export const checkImageBase64 = (data = {}) => {
  return !data?.image?.startsWith(process.env.REACT_APP_API_URL_IMG);
};
