import * as requestFromServer from "./badgeCardCrud";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { parseErrorToHandle } from "../../utils/requestUtils";
import { uploadFile } from "app/modules/utils/UploadImage";

export const createBadge = createAsyncThunk(
  "badge/createBadge",
  async (badgeForCreation, { rejectWithValue }) => {
    try {
      return requestFromServer.createBadge(badgeForCreation);
    } catch (error) {
      return rejectWithValue(parseErrorToHandle(error));
    }
  }
);

export const uploadFileBadge = createAsyncThunk(
  "badge/uploadFile",
  async (dataFileUpload, { rejectWithValue }) => {
    try {
      return uploadFile(dataFileUpload);
    } catch (error) {
      return rejectWithValue(parseErrorToHandle(error));
    }
  }
);
export const fetchBadges = createAsyncThunk(
  "badge/fetchBadges",
  async (params, { rejectWithValue }) => {
    try {
      return requestFromServer.findBadges(params);
    } catch (error) {
      return rejectWithValue(parseErrorToHandle(error));
    }
  }
);

export const fetchBadge = createAsyncThunk(
  "badge/fetchBadge",
  async (id, { rejectWithValue }) => {
    try {
      if (id) return requestFromServer.getBadgeById(id);
    } catch (error) {
      return rejectWithValue(parseErrorToHandle(error));
    }
  }
);
export const deleteBadge = createAsyncThunk(
  "badge/deleteBadge",
  async (id, { rejectWithValue }) => {
    try {
      return requestFromServer.deleteBadge(id);
    } catch (error) {
      return rejectWithValue(parseErrorToHandle(error));
    }
  }
);

export const updateBadge = createAsyncThunk(
  "badge/updateBadge",
  async (body, { rejectWithValue }) => {
    try {
      return requestFromServer.updateBadge(body);
    } catch (error) {
      return rejectWithValue(parseErrorToHandle(error));
    }
  }
);
export const deleteBadges = createAsyncThunk(
  "badge/deleteBadges",
  async (ids, { rejectWithValue }) => {
    try {
      return requestFromServer.deleteBadges({ ids });
    } catch (error) {
      return rejectWithValue(parseErrorToHandle(error));
    }
  }
);
