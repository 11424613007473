import {initSize} from "components/BootstrapTable/PaginationTotal";

export const UserStatusCssClasses = ['success', 'danger'];
export const UserStatusTitles = ['Active', 'InActive'];
export const UserTypeCssClasses = ['success', 'primary', ''];
export const UserTypeTitles = ['Business', 'Individual', ''];
export const defaultSorted = [{ dataField: 'id', order: 'asc' }];
export const initialFilter = {
  _start: 1,
  _limit: initSize,
  _sort: 'id:desc'
  //   _sort: 'id:desc',
};
