import ApiService from '../../../utils/ApiService';
export const MEMBER_URL = 'users';
export const VIDEO_URL = 'videos';
export const ADMIN_URL = 'admin-users';
export const ADMIN_GROUP_URL = 'admin-groups';
export const MEMBER_QUESTION_URL = 'user-questions';

export function getAllAdminHistories(queryParams) {
    return ApiService.get(`/admin-users-activities/${queryParams?.userId}`, {
        ...queryParams,
        userId: undefined
    });
}
export function getAllAdminGroups(queryParams) {
    return ApiService.get(`${ADMIN_GROUP_URL}/list`, queryParams);
}
export function getAllBadge(queryParams) {
    return ApiService.get(`admin-badge/list`);
}
export function updateBadge(body) {
    return ApiService.post(`admin-user-badge/save`, body);
}
export function getPermission() {
    return ApiService.get(`${ADMIN_GROUP_URL}/permission-default`);
}
export function createAdminGroup(user) {
    return ApiService.post(`${ADMIN_GROUP_URL}`, user);
}

export function updateAdminGroup(user) {
    return ApiService.put(`${ADMIN_GROUP_URL}/${user.id}`, user);
}
export function updateUserBlock(user) {
    return ApiService.put(`${MEMBER_URL}/${user.id}`, user);
}
export function unlockBlock(body) {
    return ApiService.post(`${ADMIN_URL}/unlock-lock-account`, body);
}
export function getAdminGroupById(userId) {
    return ApiService.get(`${ADMIN_GROUP_URL}/${userId}`);
}
export function getQuestionById() {
    return ApiService.get(`${MEMBER_QUESTION_URL}`);
}
export function deleteAdminGroup(userId) {
    return ApiService.delete(`${ADMIN_GROUP_URL}/${userId}`);
}

export function createAdmin(user) {
    return ApiService.post(`${ADMIN_URL}/register`, user);
}

export function updateAdmin(user) {
    return ApiService.put(`${ADMIN_URL}/${user.id}`, user);
}
export function getAllInactiveAdmins(queryParams) {
    return ApiService.get(`${ADMIN_URL}/list`, queryParams);
}

export function getAllAdmins(queryParams) {
    return ApiService.get(
        `${ADMIN_URL}/list?dbstatus=1&dbstatus=3`,
        queryParams
    );
}

export function getAdminById(userId) {
    return ApiService.get(`${ADMIN_URL}/${userId}`);
}
export function deleteAdmin(body) {
    return ApiService.post(`${ADMIN_URL}/delete/${body.id}`, body);
}
// READ
export function getAllMembers(queryParams) {
    return ApiService.get(`${MEMBER_URL}/list`, queryParams);
}

export function getInactiveMembers(queryParams) {
    return ApiService.get(`${MEMBER_URL}/list?dbstatus=10`, queryParams);
}
export function getAllGuests(queryParams) {
    return ApiService.get(`guest-users`, queryParams);
}
export function getVideosByUser(queryParams) {
    return ApiService.get(VIDEO_URL, queryParams);
}

export function getMemberById(userId) {
    return ApiService.get(`${MEMBER_URL}/${userId}`);
}
export function createUser(user) {
    return ApiService.post(MEMBER_URL, user);
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findUsers(queryParams) {
    return ApiService.post(`${MEMBER_URL}/find`, { queryParams });
}

// UPDATE => PUT: update the user on the server
export function updateUser(user) {
    return ApiService.put(`${MEMBER_URL}/${user.id}`, { user });
}

// UPDATE Status
export function updateStatusForUsers(ids, status) {
    return ApiService.post(`${MEMBER_URL}/updateStatusForUsers`, {
        ids,
        status
    });
}

// DELETE => delete the user from the server
export function deleteUser(userId) {
    return ApiService.put(`${MEMBER_URL}/${userId}`, { dbstatus: 10,is_admin:"admin", });
}

// DELETE Users by ids
export function deleteUsers(ids) {
    return ApiService.post(`${MEMBER_URL}/deleteUsers`, { ids });
}
