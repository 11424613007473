import { put, takeLatest } from "redux-saga/effects";
import { getUserByToken, getUserGroupById } from "./authCrud";
import { formatDataPermission } from "app/modules/utils/helper";

export const actionTypes = {
  Login: "[Login] Action",
  Logout: "[Logout] Action",
  Register: "[Register] Action",
  UserRequested: "[Request User] Action",
  UserLoaded: "[Load User] Auth API",
  SetUser: "[Set User] Action",
  ForceLogout: "[ForceLogout] Action",
  HandleForceLogout: "[HandleForceLogout] Action",
};

const initialAuthState = {
  user: undefined,
  groupPermissions: undefined,
  authToken: undefined,
  isForceLogout: false,
};

export const reducer = (state = initialAuthState, action) => {
  switch (action.type) {
    case actionTypes.Login: {
      const { authToken } = action.payload;
      return { authToken, user: undefined, groupPermissions: undefined };
    }

    case actionTypes.Register: {
      const { authToken } = action.payload;

      return { authToken, user: undefined, groupPermissions: undefined };
    }

    case actionTypes.Logout: {
      return initialAuthState;
    }

    case actionTypes.HandleForceLogout: {
      return initialAuthState;
    }

    case actionTypes.UserLoaded: {
      const { user, groupPermissions } = action.payload;
      return { ...state, user, groupPermissions };
    }

    case actionTypes.SetUser: {
      const { user } = action.payload;
      return { ...state, user };
    }

    case actionTypes.ForceLogout: {
      return { ...state, isForceLogout: true };
    }

    default:
      return state;
  }
};

export const actions = {
  login: (authToken) => ({ type: actionTypes.Login, payload: { authToken } }),
  register: (authToken) => ({
    type: actionTypes.Register,
    payload: { authToken },
  }),
  logout: (data) => {
    return {
      type: actionTypes.Logout,
      payload: data,
    };
  },
  HandleForceLogout: () => {
    return {
      type: actionTypes.HandleForceLogout,
    };
  },
  requestUser: (user) => ({
    type: actionTypes.UserRequested,
    payload: { user },
  }),
  fulfillUser: (user, groupPermissions = {}) => ({
    type: actionTypes.UserLoaded,
    payload: { user, groupPermissions },
  }),
  setUser: (user) => ({ type: actionTypes.SetUser, payload: { user } }),
  forceLogout: () => ({ type: actionTypes.ForceLogout }),
};

export function* saga() {
  yield takeLatest(actionTypes.Login, function* loginSaga() {
    yield put(actions.requestUser());
  });

  yield takeLatest(actionTypes.Register, function* registerSaga() {
    yield put(actions.requestUser());
  });

  yield takeLatest(actionTypes.UserRequested, function* userRequested() {
    const user = yield getUserByToken();
    const groupPermissions = yield getUserGroupById(user?.group_id?.id);
    const dataFormat = formatDataPermission(groupPermissions);
    yield put(actions.fulfillUser(user, dataFormat));
  });
}
