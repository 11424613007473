import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import axios from "axios";
import * as _redux from "./redux";
import store, { persistor } from "./redux/store";
import App from "./app/App";
import "./index.scss"; // Standard version
// import "./sass/style.react.rtl.css"; // RTL version
import "./_metronic/_assets/plugins/keenthemes-icons/font/ki.css";
import "socicon/css/socicon.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./_metronic/_assets/plugins/flaticon/flaticon.css";
import "./_metronic/_assets/plugins/flaticon2/flaticon.css";
import "./_metronic/_assets/fonts/font.css";
// Datepicker
import "react-datepicker/dist/react-datepicker.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import "notyf/notyf.min.css";
import {
  MetronicLayoutProvider,
  MetronicSplashScreenProvider,
  MetronicSubheaderProvider,
} from "./_metronic/layout";

import { createRoot } from "react-dom/client";
import "translations/i18n";
/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const { PUBLIC_URL } = process.env;

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);
_redux.setupAxios(axios, store);

root.render(
  <MetronicLayoutProvider>
    <MetronicSubheaderProvider>
      <MetronicSplashScreenProvider>
        <App store={store} persistor={persistor} basename={PUBLIC_URL} />
      </MetronicSplashScreenProvider>
    </MetronicSubheaderProvider>
  </MetronicLayoutProvider>
);
