import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import _isEmpty from 'lodash/isEmpty'
import { parseErrorToHandle } from "app/modules/utils/requestUtils";
import {
    createPolicyAPI,
    deletePolicyAPI,
    getDetailPolicyAPI,
    getPoliciesAPI,
    updatePolicyAPI,
    getPolicyTypesAPI,
} from "./policyCrud";

const initialPolicyState = {
    policies: [],
    totalCount: 0,
    detailPolicy: {},
    policyTypes: []
};

export const createPolicy = createAsyncThunk(
    "policy/createPolicy",
    async (params, { rejectWithValue }) => {
        try {
            const data = await createPolicyAPI(params);
            return { data, message: "Create a new policy successfully" };
        } catch (error) {
            return rejectWithValue(parseErrorToHandle(error));
        }
    }
);

export const deletePolicy = createAsyncThunk(
    "policy/deletePolicy",
    async (params, { rejectWithValue }) => {
        try {
            const data = await deletePolicyAPI(params);
            return { data, message: "Delete policy successfully" };
        } catch (error) {
            return rejectWithValue(parseErrorToHandle(error));
        }
    }
);

export const getDetailPolicy = createAsyncThunk(
    "policy/getDetailPolicy",
    async (params, { rejectWithValue }) => {
        try {
            const data = await getDetailPolicyAPI(params);
            return data;
        } catch (error) {
            return rejectWithValue(parseErrorToHandle(error));
        }
    }
);

export const updatePolicy = createAsyncThunk(
    "policy/updatePolicy",
    async (params, { rejectWithValue }) => {
        try {
            const data = await updatePolicyAPI(params);
            return { data, message: "Update policy successfully" };
        } catch (error) {
            return rejectWithValue(parseErrorToHandle(error));
        }
    }
);

export const getPolicies = createAsyncThunk(
    "policy/getPolices",
    async (params, { rejectWithValue }) => {
        try {
            const data = await getPoliciesAPI(params);
            return data;
        } catch (error) {
            return rejectWithValue(parseErrorToHandle(error));
        }
    }
);

export const getPolicyTypes = createAsyncThunk(
    "policy/getPolicyTypes",
    async (params, { rejectWithValue }) => {
        try {
            const data = await getPolicyTypesAPI();
            return data;
        } catch (error) {
            return rejectWithValue(parseErrorToHandle(error));
        }
    }
);

export const policySlice = createSlice({
    name: "Policy",
    initialState: initialPolicyState,
    reducers: {
        clearDetailPolicy: (state, action) => {
            state.detailPolicy = {};
        },
    },
    extraReducers: {
        [getPolicies.fulfilled]: (state, action) => {
            const { policies, total } = action.payload;
            state.policies = policies;
            state.totalCount = total;
        },
        [getPolicies.rejected]: (state, action) => {
            state.policies = [];
            state.totalCount = 0;
        },

        [getDetailPolicy.fulfilled]: (state, action) => {
            state.detailPolicy = action.payload;
        },
        [getDetailPolicy.rejected]: (state, action) => {
            state.detailPolicy = {};
        },
        [getPolicyTypes.fulfilled]: (state, action) => {
            const types = [];

            if(!_isEmpty(action.payload)) {
                action.payload.map((each) => {
                    let obj = {};

                    obj.label = each.title;
                    obj.value = each.key;

                    types.push(obj)
                })
            }

            state.policyTypes = types
        }
    },
});

export const { clearDetailPolicy } = policySlice.actions;

export const selectPolicies = (state) => state.policy.policies;
export const selectTotalPolicy = (state) => state.policy.totalCount;
export const selectDetailPolicy = (state) => state.policy.detailPolicy;
