import ApiService from '../../utils/ApiService';
export const DASHBOARD_URL = `${process.env.REACT_APP_API_URL}/dashboard`;

export function getDashboardInfoAPI() {
  return ApiService.get(`${DASHBOARD_URL}/memberStatistics`);
}

export function getInspectionStatisticsAPI() {
  return ApiService.get(`${DASHBOARD_URL}/inspectionStatistics`);
}
export function getTopTagAPI() {
  return ApiService.get(`${DASHBOARD_URL}/video/topTags`);
}

export function getContentStatisticsAPI() {
  return ApiService.get(`${DASHBOARD_URL}/contentStatistics`);
}

export function getCommentStatisticsAPI() {
  return ApiService.get(`${DASHBOARD_URL}/commentStatistics`);
}

export function getVideoPopularAPI() {
  return ApiService.get(`${DASHBOARD_URL}/video/popular`);
}

export function getVideoTopViewAPI() {
  return ApiService.get(`${DASHBOARD_URL}/video/topView`);
}

export function getVideoTopLikeAPI() {
  return ApiService.get(`${DASHBOARD_URL}/video/topLike`);
}

export function getVideoTopCommentAPI() {
  return ApiService.get(`${DASHBOARD_URL}/video/topComment`);
}

export function getVideoTopReviewAPI() {
  return ApiService.get(`${DASHBOARD_URL}/video/topReview`);
}

export function getVideoTopShareAPI() {
  return ApiService.get(`${DASHBOARD_URL}/video/topShare`);
}

export function getMemberStatisticsByAgeAPI() {
  return ApiService.get(`${DASHBOARD_URL}/member/memberStatisticsByAge`);
}

export function getMemberStatisticsByGenderAPI() {
  return ApiService.get(`${DASHBOARD_URL}/member/memberStatisticsByGender`);
}

export function getMemberStatisticsByOSAPI() {
  return ApiService.get(`${DASHBOARD_URL}/member/memberStatisticsByOS`);
}

export function getVideoAccumulationAPI() {
  return ApiService.get(`${DASHBOARD_URL}/video/accumulation`);
}

export function getTopCreatorAPI() {
  return ApiService.get(`${DASHBOARD_URL}/creator/topCreators`);
}
