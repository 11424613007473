import { createSlice } from "@reduxjs/toolkit";

const initialCategoryState = {
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    entities: null,
    entitiesPreview: null,
    categoryForEdit: undefined,
    lastError: null,
    listGroupCategories: null,
};
export const callTypes = {
    list: "list",
    action: "action",
};

export const categorySlice = createSlice({
    name: "Category",
    initialState: initialCategoryState,
    reducers: {
        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.error}`;
            if (action.payload.callType === callTypes.list) {
                state.listPreviewLoading = true;
                state.listUpdateLoading = true;
                state.listLoading = false;
            } else {
                state.actionsLoading = false;
            }
        },
        startCall: (state, action) => {
            state.error = null;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = true;
                state.listPreviewLoading = true;
                state.listUpdateLoading = true;
            } else {
                state.actionsLoading = true;
            }
        },

        // get category ById
        categoryFetched: (state, action) => {
            state.actionsLoading = false;
            state.categoryForEdit = action.payload.categoryForEdit;
            state.error = null;
            state.actionsLoading = false;
        },
        // find category
        categoriesFetched: (state, action) => {
            const entities = action.payload;
            state.listLoading = false;
            state.error = null;
            state.entities = entities;
            state.totalCount = 0;
            state.actionsLoading = false;
        },
        listGroupCategoriesFetched: (state, action) => {
            const listGroupCategories = action.payload;

            state.listLoading = false;
            state.error = null;
            state.listGroupCategories = listGroupCategories;
            state.totalCount = 0;
            state.actionsLoading = false;
        },
        //
        categoriesPreviewFetched: (state, action) => {
            const entities = action.payload;
            state.listLoading = false;
            state.actionsLoading = false;
            state.listPreviewLoading = false;
            state.error = null;
            state.entitiesPreview = entities;
        },
        categoriesUpdateBulk: (state, action) => {
            state.listLoading = false;
            state.actionsLoading = false;
            state.listUpdateLoading = false;
            state.error = null;
        },
        // create category
        categoryCreated: (state, action) => {
            state.actionsLoading = false;
            state.error = null;
            state.entities.push(action.payload.category);
        },
        // update category
        categoryUpdated: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
        },
        // delete category
        categoryDeleted: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
        },
        // delete category
        categoriesDeleted: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
        },
        // categoryUpdateState
        categoryStatusUpdated: (state, action) => {
            state.actionsLoading = false;
            state.error = null;
        },
    },
});
