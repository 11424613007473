import ApiService from "../../utils/ApiService";
export const COMMENT_URL = `${process.env.REACT_APP_API_URL}/admin-comments`;

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findCommentsAPI(queryParams) {
  return ApiService.get(`${COMMENT_URL}/list`, queryParams);
}
export function saveIngredientAPI(body) {
  return ApiService.post(`${COMMENT_URL}/set-fridge-ingredient`, body);
}
export function pinCommentAPI(body) {
  return ApiService.post(`${COMMENT_URL}/pin-comment`, body);
}
export function findCommentAPI(id) {
  return ApiService.get(`${COMMENT_URL}/list`, { id_contains: id });
}
export function findCommentsAPIExcel(queryParams) {
  return ApiService.get(`${COMMENT_URL}/list`, queryParams);
}
// GET number of report detail by ids
export function fetchNumberReportDetailAPI(queryParams) {
  return ApiService.get(`${COMMENT_URL}/list-report`, queryParams);
}

// GET history of report detail by ids
export function fetchHistoryCommentDetailAPI(queryParams) {
  return ApiService.get(
    `${COMMENT_URL}/list-status-history?_limit=1000`,
    queryParams
  );
}

// POST create report comment
export function createReportCommentAPI(body) {
  return ApiService.post(`${COMMENT_URL}/change-status`, body);
}
// PUT comment categoty
export function updateSubCategoryAPI(body, id) {
  return ApiService.put(`/comment-categories/${id}`, body);
}
