import ApiService from "../../utils/ApiService";

export const BADGE_URL = `${process.env.REACT_APP_API_URL}/admin-badge`;

// CREATE =>  POST: add a new Badge to the server
export function createBadge(badge) {
  return ApiService.post(BADGE_URL, badge);
}

export function findBadges(queryParams) {
  return ApiService.get(`${BADGE_URL}/list`, queryParams);
}

export function getBadgeById(badgeId) {
  return ApiService.get(`${BADGE_URL}/${badgeId}`);
}

export function updateBadge(badge) {
  return ApiService.put(`${BADGE_URL}/${badge.id}`, badge);
}

export function deleteBadge(badgeId) {
  return ApiService.delete(`${BADGE_URL}/${badgeId}`);
}

// DELETE Badges by ids
export function deleteBadges(body) {
  return ApiService.put(`${BADGE_URL}/bulkdelete`, body);
}
