import { createSlice } from "@reduxjs/toolkit";
import _get from "lodash/get";

import {
  onGetSplashes,
  onGetDetails,
  onCreateSplash,
  uploadSplashImages,
  onUpdateSplashDetails,
  onDeleteSplash,
  onBulkDeleteSplashes,
} from "./splashActions";

const initialState = {
  listLoading: true,
  listData: [],
  detailsLoading: true,
  detailsData: {},
  requestLoading: false,
};

export const splashSlice = createSlice({
  name: "Splash",
  initialState,
  extraReducers: {
    [onGetSplashes.pending]: (state) => {
      state.listLoading = true;
      state.listData = [];
    },
    [onGetSplashes.fulfilled]: (state, action) => {
      state.listLoading = false;
      state.listData = _get(action, ["payload", "data"], []);
    },
    [onGetDetails.pending]: (state) => {
      state.detailsLoading = true;
      state.detailsData = {};
    },
    [onGetDetails.fulfilled]: (state, action) => {
      state.detailsLoading = false;
      state.detailsData = _get(action, ["payload", "data"], {});
    },
    [(onCreateSplash.pending,
    uploadSplashImages.pending,
    onUpdateSplashDetails.pending,
    onDeleteSplash.pending,
    onBulkDeleteSplashes.pending)]: (state) => {
      state.requestLoading = true;
    },
    [(onCreateSplash.fulfilled,
    onCreateSplash.rejected,
    uploadSplashImages.fulfilled,
    uploadSplashImages.rejected,
    onUpdateSplashDetails.fulfilled,
    onUpdateSplashDetails.rejected,
    onDeleteSplash.fulfilled,
    onDeleteSplash.rejected,
    onBulkDeleteSplashes.fulfilled,
    onBulkDeleteSplashes.rejected)]: (state) => {
      state.requestLoading = false;
    },
  },
});
