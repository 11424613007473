import {initSize} from "components/BootstrapTable/PaginationTotal";

export const CONSTS = {
    STORE_KEYS: {
        USER: {
            PROFILE: "__stupf",
            ID: "__stuid",
            TOKEN: "__stutk",
            IP: "__stuip",
        },
    },
};

export const RememberID = "BUTTERYUM_REMEMBER_ID";
export const CountIncorrectLogin = "BUTTERYUM_INCORRECT_LOGIN";
export const DefaultSorted = [{ dataField: "id", order: "asc" }];
export const initialFilter = { _start: 1, _limit: initSize, _sort: "id:desc" };
export const DefaultFormatDate = "YYYY-MM-DD";
export const DefaultFormatDateTable = "YYYY-MM-DD hh:mm A";
export const DefaultFormatDateTime = "MM/DD/YYYY hh:mm A";

export const DefaultErrorMsg = "Something went wrong, please contact admin";

export const themeColors = {
    primary: "#41b883",
    primaryMedium: "#b4e4ce",
    primaryLight: "#f7fcfa",
    secondary: "#ff227d",
    accent: "#797bf2",
    accentMedium: "#d4b3ff",
    accentLight: "#b8ccff",
    success: "#06d6a0",
    info: "#039BE5",
    warning: "#faae42",
    danger: "#FF7273",
    purple: "#8269B2",
    blue: "#37C3FF",
    green: "#93E088",
    yellow: "#FFD66E",
    orange: "#FFA981",
    lightText: "#a2a5b9",
    fadeGrey: "#ededed",
};
