import { createAsyncThunk } from "@reduxjs/toolkit";
import { parseErrorToHandle } from "app/modules/utils/requestUtils";
import { getPins, togglePin } from "./pinCrud";

export const onGetPins = createAsyncThunk(
  "pin/getPins",
  async (p, { rejectWithValue }) => {
    try {
      const response = await getPins();
      return response;
    } catch (error) {
      return rejectWithValue(parseErrorToHandle(error));
    }
  }
);

export const onTogglePin = createAsyncThunk(
  "pin/togglePin",
  async (body, { rejectWithValue }) => {
    try {
      const response = await togglePin(body);
      return response;
    } catch (error) {
      return rejectWithValue(parseErrorToHandle(error));
    }
  }
);
