import { uploadFileBlood } from 'app/modules/utils/UploadImage';
import * as requestFromServer from './pollCardCrud';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { parseErrorToHandle } from 'app/modules/utils/requestUtils';

export const fetchPolls = createAsyncThunk(
  'poll/fetchPolls',
  async (params, { rejectWithValue }) => {
    try {
      return requestFromServer.findPolls(params);
    } catch (error) {
      return rejectWithValue(parseErrorToHandle(error));
    }
  }
);
export const getAnswerId = createAsyncThunk(
  'poll/getAnswerId',
  async (params, { rejectWithValue }) => {
    try {
      return requestFromServer.getAnswerId(params);
    } catch (error) {
      return rejectWithValue(parseErrorToHandle(error));
    }
  }
);

export const updatePollsBulk = createAsyncThunk(
  'poll/updatePollsBulk',
  async (params, { rejectWithValue }) => {
    try {
      return requestFromServer.updatePollsBulk(params);
    } catch (error) {
      return rejectWithValue(parseErrorToHandle(error));
    }
  }
);
export const fetchPoll = createAsyncThunk('poll/fetchPoll', async (id, { rejectWithValue }) => {
  try {
    if (!id) return;
    return requestFromServer.getPollById(id);
  } catch (error) {
    return rejectWithValue(parseErrorToHandle(error));
  }
});

export const deletePoll = createAsyncThunk('poll/deletePoll', async (id, { rejectWithValue }) => {
  try {
    return requestFromServer.deletePoll(id);
  } catch (error) {
    return rejectWithValue(parseErrorToHandle(error));
  }
});
export const commentPoll = createAsyncThunk('poll/comment', async (params, { rejectWithValue }) => {
  try {
    return requestFromServer.getComments(params);
  } catch (error) {
    return rejectWithValue(parseErrorToHandle(error));
  }
});
export const createPoll = createAsyncThunk(
  'poll/deletePoll',
  async (params, { rejectWithValue }) => {
    try {
      return requestFromServer.createPoll(params);
    } catch (error) {
      return rejectWithValue(parseErrorToHandle(error));
    }
  }
);
export const createQuestion = createAsyncThunk(
  'poll/createQuestion',
  async (params, { rejectWithValue }) => {
    try {
      return requestFromServer.createQuestion(params);
    } catch (error) {
      return rejectWithValue(parseErrorToHandle(error));
    }
  }
);

export const editQuestion = createAsyncThunk(
  'poll/editQuestion',
  async (params, { rejectWithValue }) => {
    try {
      return requestFromServer.editQuestion(params);
    } catch (error) {
      return rejectWithValue(parseErrorToHandle(error));
    }
  }
);
export const createAnswer = createAsyncThunk(
  'poll/createAnswer',
  async (params, { rejectWithValue }) => {
    try {
      return requestFromServer.createAnswer(params);
    } catch (error) {
      return rejectWithValue(parseErrorToHandle(error));
    }
  }
);

export const editAnswer = createAsyncThunk(
  'poll/editAnswer',
  async (params, { rejectWithValue }) => {
    try {
      return requestFromServer.editAnswer(params);
    } catch (error) {
      return rejectWithValue(parseErrorToHandle(error));
    }
  }
);

export const updatePoll = createAsyncThunk(
  'poll/updatePoll',
  async (params, { rejectWithValue }) => {
    try {
      return requestFromServer.updatePoll(params);
    } catch (error) {
      return rejectWithValue(parseErrorToHandle(error));
    }
  }
);

export const deletePolls = createAsyncThunk(
  'poll/deletePolls',
  async (ids, { rejectWithValue }) => {
    try {
      return requestFromServer.deletePolls({ list_id: ids });
    } catch (error) {
      return rejectWithValue(parseErrorToHandle(error));
    }
  }
);

export const deleteQuestion = createAsyncThunk(
  'poll/deleteQuestion',
  async (id, { rejectWithValue }) => {
    try {
      return requestFromServer.deletePolls(id);
    } catch (error) {
      return rejectWithValue(parseErrorToHandle(error));
    }
  }
);

export const deleteAnswer = createAsyncThunk(
  'poll/deleteAnswer',
  async (id, { rejectWithValue }) => {
    try {
      return requestFromServer.deleteAnswer(id);
    } catch (error) {
      return rejectWithValue(parseErrorToHandle(error));
    }
  }
);
export const updateInspection = createAsyncThunk(
  'poll/updateInspection',
  async (params, { rejectWithValue }) => {
    try {
      return requestFromServer.updateInspection(params);
    } catch (error) {
      return rejectWithValue(parseErrorToHandle(error));
    }
  }
);
export const uploadAnswer = createAsyncThunk(
  'poll/uploadAnswer',
  async (params, { rejectWithValue }) => {
    try {
      return uploadFileBlood(params);
    } catch (error) {
      return rejectWithValue(parseErrorToHandle(error));
    }
  }
);
