import React, { useState, useEffect } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { CardHeader, CardHeaderToolbar } from "components/controls";
import { ModalProgressBar } from "components/controls";
import { useTranslation } from "react-i18next";

export function UserEditDialogHeader({ id, onClick }) {
    const { t } = useTranslation();
    // Users Redux state
    const { userForEdit, actionsLoading } = useSelector(
        (state) => ({
            userForEdit: state.users.userForEdit,
            actionsLoading: state.users.actionsLoading,
        }),
        shallowEqual
    );
    const [title, setTitle] = useState("");
    // Title counting
    useEffect(() => {
        let _title = id ? "" : t("admin_new_admin");
        if (userForEdit && id) {
            _title = `${t("admin_edit_admin")} '${userForEdit.name}'`;
        }

        setTitle(_title);
        // eslint-disable-next-line
    }, [userForEdit, actionsLoading]);
    return (
        <>
            {actionsLoading && <ModalProgressBar />}
            <CardHeader title={title}>
                <CardHeaderToolbar>
                    <button type="button" className="btn btn-light mg-l-15" onClick={onClick}>
                        {t("button_back_list")}
                    </button>
                </CardHeaderToolbar>
            </CardHeader>
        </>
    );
}
