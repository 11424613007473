import ApiService from "../../utils/ApiService";
export const POLICY_URL = `${process.env.REACT_APP_API_URL}/policies`;

export function createPolicyAPI(params) {
    return ApiService.post(`${POLICY_URL}`, params);
}

export function deletePolicyAPI(id) {
    return ApiService.delete(`${POLICY_URL}/${id}`);
}

export function getDetailPolicyAPI(id) {
    return ApiService.get(`${POLICY_URL}/${id}`);
}

export function updatePolicyAPI({ id, params }) {
    return ApiService.put(`${POLICY_URL}/${id}`, params);
}

export function getPoliciesAPI(queryParams) {
    return ApiService.get(`${POLICY_URL}`, queryParams);
}

export function getPolicyTypesAPI() {
    return ApiService.get(`${POLICY_URL}/type`);
}
