import { createSlice } from "@reduxjs/toolkit";

const initialCreatorState = {
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    entities: null,
    entitiesPreview: null,
    creatorForEdit: undefined,
    lastError: null,
};
export const callTypes = {
    list: "list",
    action: "action",
};

export const creatorSlice = createSlice({
    name: "Creator",
    initialState: initialCreatorState,
    reducers: {
        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.error}`;
            if (action.payload.callType === callTypes.list) {
                state.listPreviewLoading = true;
                state.listUpdateLoading = true;
                state.listLoading = false;
            } else {
                state.actionsLoading = false;
            }
        },
        startCall: (state, action) => {
            state.error = null;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = true;
                state.listPreviewLoading = true;
                state.listUpdateLoading = true;
            } else {
                state.actionsLoading = true;
            }
        },

        endCall: (state) => {
            state.error = null;
            state.listLoading = false;
            state.listPreviewLoading = false;
            state.listUpdateLoading = false;
            state.actionsLoading = false;
        },

        // get creator ById
        creatorFetched: (state, action) => {
            state.actionsLoading = false;
            state.creatorForEdit = action.payload.creatorForEdit;
            state.error = null;
            state.actionsLoading = false;
        },
        // find creator
        creatorsFetched: (state, action) => {
            const { data, total } = action.payload;
            state.error = null;
            state.entities = data;
            state.totalCount = total;
            state.listLoading = false;
            state.listPreviewLoading = false;
            state.listUpdateLoading = false;
            state.actionsLoading = false;
        },

        creatorsUpdateBulk: (state, action) => {
            state.listLoading = false;
            state.actionsLoading = false;
            state.listUpdateLoading = false;
            state.error = null;
        },
        // create creator
        creatorCreated: (state, action) => {
            state.actionsLoading = false;
            state.error = null;
        },
        // update creator
        creatorUpdated: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
        },
        // delete creator
        creatorDeleted: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
        },
        // delete creator
        creatorsDeleted: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
        },
        // creatorUpdateState
        creatorStatusUpdated: (state, action) => {
            state.actionsLoading = false;
            state.error = null;
        },
    },
});
