import React from "react";
import { Modal } from "react-bootstrap";
import { toAbsoluteUrl } from "_metronic/_helpers";

function ConfirmModal({
  show,
  onHide = null,
  header,
  imgSrc,
  title,
  content,
  subContent,
  yesButton,
  yesAction,
  cancelButton,
  cancelAction,
}) {
  return (
    <Modal
      show={show}
      onHide={onHide}
     

      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">{header}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-align-center">
        {imgSrc && (
          <img
            src={toAbsoluteUrl(imgSrc)}
            alt="unlock"
            className="mg-b-15 wd-75"
          />
        )}
        <div className="mg-b-10 modal-title">{title}</div>
        <div className="mg-b-10">{content}</div>
        {subContent && <div className="mg-b-10 color-red">{subContent}</div>}
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button
            type="button"
            onClick={yesAction}
            className="btn btn-primary btn-elevate"
          >
            {yesButton}
          </button>
          {cancelButton && cancelAction && (
            <button
              type="button"
              onClick={cancelAction}
              className="btn btn-light btn-elevate mg-l-10"
            >
              {cancelButton}
            </button>
          )}
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export default ConfirmModal;
