import ApiService from "../../utils/ApiService";
export const VIDEO_URL = `${process.env.REACT_APP_API_URL}/videos`;
export const WEEKLY_URL = `${process.env.REACT_APP_API_URL}/weeklies`;

export function findVideos(queryParams) {
    return ApiService.get(`${VIDEO_URL}/list`, queryParams);
}

// CREATE =>  POST: add a new Weekly to the server
export function createWeekly(weekly) {
    return ApiService.post(WEEKLY_URL, { weekly });
}

// READ
export function getAllWeeklies(queryParams) {
    return ApiService.get(`${WEEKLY_URL}/list`, queryParams);
}

export function getWeeklyById(weeklyId) {
    return ApiService.get(`${WEEKLY_URL}/${weeklyId}`);
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findWeeklies(queryParams) {
    return ApiService.post(`${WEEKLY_URL}`, { queryParams });
}

// UPDATE => PUT: update the Weekly on the server
export function updateWeekly(body) {
    return ApiService.post(`${WEEKLY_URL}/updateWeekly`, body);
}

// UPDATE Status
export function updateStatusForWeeklies(ids, status) {
    return ApiService.post(`${WEEKLY_URL}/updateStatusForWeeklies`, {
        ids,
        status,
    });
}

// DELETE => delete the Weekly from the server
export function deleteWeekly(weeklyId) {
    return ApiService.delete(`${WEEKLY_URL}/${weeklyId}`);
}

// DELETE Weeklies by ids
export function deleteWeeklies(ids) {
    return ApiService.post(`${WEEKLY_URL}/deleteWeeklies`, { ids });
}
