import ApiService from "../../utils/ApiService";
export const NOTIFICATION_PUSH_URL = `${process.env.REACT_APP_API_URL}/notification-pushes`;
export const NOTIFICATION_PUSH_GROUP_URL = `${process.env.REACT_APP_API_URL}/notification-push-groups`;

// **notification push**//
export function getListPushAPI(queryParams) {
    return ApiService.get(`${NOTIFICATION_PUSH_URL}/list`, queryParams);
}

export function getListFilterAPI() {
    return ApiService.get(`${NOTIFICATION_PUSH_URL}/filters/setting`);
}

export function createPushAPI(params) {
    return ApiService.post(`${NOTIFICATION_PUSH_URL}`, params);
}

export function getDetailPushAPI(id) {
    return ApiService.get(`${NOTIFICATION_PUSH_URL}/${id}`);
}

export function EditPushAPI(id, params) {
    return ApiService.put(`${NOTIFICATION_PUSH_URL}/${id}`, params);
}

export function deletePushAPI(id) {
    return ApiService.delete(`${NOTIFICATION_PUSH_URL}/${id}`);
}

export function deleteMultiplePushAPI(params) {
    return ApiService.post(`${NOTIFICATION_PUSH_URL}/bulkdelete`, params);
}

// **notification group push**//
export function getListPushGroupAPI(queryParams) {
    return ApiService.get(`${NOTIFICATION_PUSH_GROUP_URL}/list`, queryParams);
}

export function searchUserAPI(queryParams) {
    return ApiService.get(`${NOTIFICATION_PUSH_GROUP_URL}/users/search`, queryParams);
}

export function createEditGroupUserConditionAPI(params) {
    return ApiService.post(`${NOTIFICATION_PUSH_GROUP_URL}/users/savecondition`, params);
}

export function createEditGroupUserIdAPI(params) {
    return ApiService.post(`${NOTIFICATION_PUSH_GROUP_URL}/users/savelistid`, params);
}
export function createEditGroupUserIdAPIExcel(params) {
    return ApiService.post(`${NOTIFICATION_PUSH_GROUP_URL}/users/savecondition`, params);
}

export function deleteGroupUserAPI(id) {
    return ApiService.delete(`${NOTIFICATION_PUSH_GROUP_URL}/${id}`);
}

export function deleteMultipleGroupUserAPI(params) {
    return ApiService.post(`${NOTIFICATION_PUSH_GROUP_URL}/bulkdelete`, params);
}

export function GetDetailGroupUserAPI(id) {
    return ApiService.get(`${NOTIFICATION_PUSH_GROUP_URL}/${id}`);
}

export function DownloadFileAPI(id) {
    return ApiService.get(`${NOTIFICATION_PUSH_GROUP_URL}/users/download/${id}`);
}
export function DownloadFileSample() {
    return ApiService.get(`${NOTIFICATION_PUSH_GROUP_URL}/users/download_sample_file`);
}
