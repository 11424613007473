import { createAsyncThunk } from '@reduxjs/toolkit';
import { parseErrorToHandle } from 'app/modules/utils/requestUtils';
import * as requestFromServer from './weeklyCrud';

export const fetchWeeklies = createAsyncThunk(
  'weekly/getAllWeeklies',
  async (params, { rejectWithValue }) => {
    try {
      return requestFromServer.getAllWeeklies(params);
    } catch (error) {
      return rejectWithValue(parseErrorToHandle(error));
    }
  }
);

export const searchVideos = createAsyncThunk(
  'weekly/searchVideos',
  async (params, { rejectWithValue }) => {
    try {
      return requestFromServer.findVideos(params);
    } catch (error) {
      return rejectWithValue(parseErrorToHandle(error));
    }
  }
);

export const updateWeekly = createAsyncThunk(
  'weekly/updateWeekly',
  async (body, { rejectWithValue }) => {
    try {
      return requestFromServer.updateWeekly(body);
    } catch (error) {
      return rejectWithValue(parseErrorToHandle(error));
    }
  }
);
