import { createAsyncThunk } from "@reduxjs/toolkit";
import { parseErrorToHandle } from "app/modules/utils/requestUtils";
import { uploadFile } from "app/modules/utils/UploadImage";
import * as requestFromServer from "./bannerCardCrud";

export const fetchBanners = createAsyncThunk(
  "banner/fetchBanners",
  async (params, { rejectWithValue }) => {
    try {
      return requestFromServer.findBanners(params);
    } catch (error) {
      return rejectWithValue(parseErrorToHandle(error));
    }
  }
);
export const updateBannersBulk = createAsyncThunk(
  "banner/updateBannersBulk",
  async (params, { rejectWithValue }) => {
    try {
      return requestFromServer.updateBannersBulk(params);
    } catch (error) {
      return rejectWithValue(parseErrorToHandle(error));
    }
  }
);

export const fetchBanner = createAsyncThunk(
  "banner/fetchBanner",
  async (id, { rejectWithValue }) => {
    try {
      if (id) return requestFromServer.getBannerById(id);
    } catch (error) {
      return rejectWithValue(parseErrorToHandle(error));
    }
  }
);

export const deleteBanner = createAsyncThunk(
  "banner/deleteBanner",
  async (id, { rejectWithValue }) => {
    try {
      return requestFromServer.deleteBanner(id);
    } catch (error) {
      return rejectWithValue(parseErrorToHandle(error));
    }
  }
);
export const createBanner = createAsyncThunk(
  "banner/createBanner",
  async (bannerForCreation, { rejectWithValue }) => {
    try {
      const response = await requestFromServer.createBanner(bannerForCreation);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateBanner = createAsyncThunk(
  "banner/updateBanner",
  async ({ id, body }, { rejectWithValue }) => {
    try {
      const response = await requestFromServer.updateBanner({ id, body });
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteBanners = createAsyncThunk(
  "banner/deleteBanners",
  async (ids, { rejectWithValue }) => {
    try {
      return requestFromServer.deleteBanners({ list_id: ids });
    } catch (error) {
      return rejectWithValue(parseErrorToHandle(error));
    }
  }
);
export const uploadFileBanner = createAsyncThunk(
  "banner/uploadFile",
  async (dataFileUpload, { rejectWithValue }) => {
    try {
      return uploadFile(dataFileUpload);
    } catch (error) {
      return rejectWithValue(parseErrorToHandle(error));
    }
  }
);
