import moment from "moment";
import { Notyf } from "notyf";
import i18n from "../../../translations/i18n";

const notyf = new Notyf({
  duration: 3000,
  position: {
    x: "right",
    y: "bottom",
  },
});

export function formatDateFrom(date) {
  if (date) {
    return moment(date)
      .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
      .toISOString();
  }
  return "";
}

export function markEmail(data) {
  if (!data) return;
  const [name, email] = data.split("@");
  if (name.length <= 3) {
    return `${name.slice(0, 1)}***@${email}`;
  }
  if (name.length <= 5) {
    return `${name.slice(0, 3)}***@${email}`;
  }
  return `${name.slice(0, 4)}***@${email}`;
}
export function markName(data) {
  if (!data) return;
  if (data.length <= 3) {
    return `${data.slice(0, 1)}***`;
  }
  if (data.length <= 3) {
    return `${data.slice(0, 3)}***`;
  }
  return `${data.slice(0, 4)}***`;
}

export function formatDateTo(date) {
  if (date) {
    return moment(date)
      .set({ hour: 23, minute: 59, second: 59, millisecond: 59 })
      .toISOString();
  }
  return "";
}

export function getNameofMonth(date) {
  const monthNames = [
    i18n.t("january"),
    i18n.t("february"),
    i18n.t("march"),
    i18n.t("april"),
    i18n.t("may"),
    i18n.t("june"),
    i18n.t("july"),
    i18n.t("august"),
    i18n.t("september"),
    i18n.t("october"),
    i18n.t("november"),
    i18n.t("december"),
  ];
  return monthNames[new Date(date).getMonth()];
}

export function transformData(data) {
  const headerName = [
    { video: "Video" },
    { comment: "Comment" },
    { review: "Reviews" },
    { added_to_recipebook: "Recipe Book" },
    { added_to_mealplan: "Meal Plan" },
    { added_to_shoppingcart: "Add To Shopping List" },
  ];
  const result = [];
  for (var i = 0; i < headerName.length; i++) {
    const keyHeader = Object.keys(headerName[i])[0];
    const item = {
      header: headerName[i][keyHeader],
    };
    const dataLength = Object.keys(data).length;
    for (var j = 0; j < dataLength; j++) {
      const keyResult = Object.keys(data)[j];
      item[keyResult] = data[keyResult][keyHeader];
    }
    result.push(item);
  }
  return result;
}

export function formatNumber(number) {
  if (typeof number === "number") {
    return number.toFixed(0).replace(/./g, function (c, i, a) {
      return i > 0 && c !== "." && (a.length - i) % 3 === 0 ? "," + c : c;
    });
  }
  return number;
}

const hasPrefix = (action, prefix) => {
  for (var i = 0; i < prefix.length; i++) {
    if (action.type.startsWith(prefix[i])) {
      return true;
    }
  }
  return false;
};
const isPending = (action) => action.type.endsWith("/pending");
const isFulfilled = (action) => action.type.endsWith("/fulfilled");
const isRejected = (action) => action.type.endsWith("/rejected");

export const isPendingAction = (prefix) => (action) => {
  // Note: this cast to AnyAction could also be `any` or whatever fits your case best
  return hasPrefix(action, prefix) && isPending(action);
};

export const isRejectedAction = (prefix) => (action) => {
  // Note: this cast to AnyAction could also be `any` or whatever fits your case best - like if you had standardized errors and used `rejectWithValue`
  return hasPrefix(action, prefix) && isRejected(action);
};

export const isFulfilledAction = (prefix) => (action) => {
  return hasPrefix(action, prefix) && isFulfilled(action);
};
export const formatDataPermission = (data) => {
  const dataFormat = {};
  data.admin_group_permissions.forEach((item) => {
    item.controllers.forEach((itemController) => {
      itemController.actions.forEach((itemAction) => {
        dataFormat[
          `${item.group_code}-${itemController.controller_code}-${itemAction.action_code}`
        ] = itemAction.is_allowed;
      });
    });
  });
  return dataFormat;
};

export const toastReturnAPI = (message) => {
  if (message) {
    notyf.success(message);
    return;
  }
  notyf.success("Successful");
};

export const toastReturnAPIError = (message) => {
  notyf.error(message);
};

export const getCookie = (cname) => {
  var name = cname + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
};
