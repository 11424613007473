import { CountIncorrectLogin, RememberID } from "app/modules/utils/Constant";
import { getCookie } from "app/modules/utils/helper";
import { useFormik } from "formik";
import { get } from "lodash";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import * as Yup from "yup";
import { linkCodeTotp, login, loginStep2, newQrTotp } from "../_redux/authCrud";
import * as auth from "../_redux/authRedux";
import ConfirmModal from "../components/ConfirmModal";
import IncorrectLoginModal from "../components/IncorrectLoginModal";

function Login(props) {
  const initialValues = {
    email: localStorage.getItem(RememberID) || "",
    password: "",
  };

  const { t } = useTranslation();
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  // incorrect login
  const [isOTP, setIsOTP] = useState("login");

  const [content, setContent] = useState("");
  const [token, setToken] = useState("");
  const [linkImageQR, setLinkImageQR] = useState("/media/nodata2.png");
  const [otpCode, setOtpCode] = useState("");
  const [otpCode1, setOtpCode1] = useState("");
  const [isDisplayIncorrectModal, setDisplayIncorrectModal] = useState(false);
  // over 90 days from the last time changePW
  const [isDisplayChangePwGuildModal, setDisplayChangePwGuildModal] =
    useState(false);
  // lock account
  const [isDisplayUnlockAccModal, setDisplayUnlockAccModal] = useState(false);
  // force login
  const [isDisplayForceLoginModal, setDisplayForceLoginModal] = useState(false);
  // service unavaliable
  const [isDisplayUnavaliableModal, setDisplayUnavaliableModal] =
    useState(false);

  const [isLockedModal, setLockedModal] = useState(false);
  const [isOTPModal, setOTPModal] = useState(false);
  const [isLinkApp, setLinkApp] = useState(false);
  // account deleted
  const [isDisplayDeletedAccModal, setDisplayDeletedAccModal] = useState(false);

  const [remember, setRemember] = useState(true);
  const [dataMultipleAccess, setDataMultipleAccess] = useState(null);

  let currentCount = localStorage.getItem(CountIncorrectLogin) || 0;

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email("Please enter a valid email address.")
      .required(t("login_email_is_required")),
    password: Yup.string().required(t("login_password_is_required")),
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      enableLoading();
      let code = getCookie(values.email);
      login(values.email, values.password, code)
        .then((data) => {
          disableLoading();
          if (data?.token) setToken(data?.token);
          if (!data?.is_totp_linked) {
            setLinkImageQR("/media/nodata2.png");
            setIsOTP("qr");
            return;
          }
          setIsOTP("otp");
        })
        .catch((error) => {
          const code = get(error, "messages[0].message[0].messages[0].id");

          if (code === "password_invalid" || code === "email_invalid") {
            setDisplayIncorrectModal(true);
            localStorage.setItem(CountIncorrectLogin, +currentCount + 1);
          } else if (code === "otp_sent_too_much") {
            setOTPModal(true);
            setContent(
              get(error, "messages[0].message[0].messages[0].message")
            );
          } else if (code === "password_change") {
            setDisplayChangePwGuildModal(true);
          } else if (code === "account_dormant") {
            setDisplayUnlockAccModal(true);
          } else if (code === "account_delete") {
            setDisplayDeletedAccModal(true);
          } else if (code === "admin_user_login_locked") {
            setLockedModal(true);
          } else if (code === "user_online") {
            const data = get(error, "messages[0].message[0].messages[0].data");
            setDataMultipleAccess(data);
            setDisplayForceLoginModal(true);
          } else {
            setDisplayUnavaliableModal(true);
          }
        })
        .finally(() => {
          disableLoading();
          setSubmitting(false);
        });
    },
  });
  const handleLoginStep2 = () => {
    if (!otpCode) {
      alert("please check email and enter OTP");
      return;
    }
    enableLoading();
    loginStep2({ token, otp_code: otpCode })
      .then((res) => {
        props.login(res.jwt);
      })
      .catch((error) => {
        disableLoading();

        const content = get(
          error,
          "messages[0].message[0].messages[0].message"
        );
        const id = get(error, "messages[0].message[0].messages[0].id");
        const code = get(error, "messages[0].message[0].messages[0].data.code");
        if (id === "token_expired") {
          alert("please login again");
          setIsOTP(false);
          return;
        }
        if (id === "user_online") {
          loginStep2({ token, otp_code: otpCode, code }).then((res) => {
            disableLoading();
            props.login(res.jwt);
          });
        }
        if (id === "token_expired") {
          alert("please login again");
          setIsOTP(false);
          return;
        }

        setOTPModal(true);
        setContent(content);
      });
  };
  const onChangePasswordHandle = (query) => {
    if (query) {
      history.push(`/auth/change-password?status=${query}`);
    } else {
      history.push("/auth/change-password");
    }
  };

  const handleConfirmOTP = () => {
    if (!otpCode1) {
      alert("please check email and enter OTP");
      return;
    }
    linkCodeTotp({ token, otp_code: otpCode1 })
      .then((res) => {
        if (res?.is_totp_linked) {
          setIsOTP("otp");
        }
      })
      .catch((error) => {
        disableLoading();
        const content = get(
          error,
          "messages[0].message[0].messages[0].message"
        );
        setOTPModal(true);
        setContent(content);
      });
  };

  const handleChangeDevice = () => {
    enableLoading();
    newQrTotp({ token })
      .then((res) => {
        disableLoading();
        setLinkImageQR(res?.qr_image);
      })
      .catch((error) => {
        disableLoading();
        const content = get(
          error,
          "messages[0].message[0].messages[0].message"
        );
        setOTPModal(true);
        setContent(content);
      });
  };
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleLoginStep2();
    }
  };
  const passwordForm = { ...formik.getFieldProps("password") };
  delete passwordForm.value;

  return (
    <div className="login-form login-signin" id="kt_login_signin_form">
      {/* begin::Head */}
      <div className="text-center mb-10 mb-lg-20">
        {isOTP === "qr" && (
          <>
            <div className="align-items-center d-flex">
              <span className={`btn btn-primary border-rounded mg-r-20`}>
                1
              </span>
              <span className="text-muted font-weight-bold">
                Please install Google Authentication
              </span>
              <span
                onClick={() => setLinkApp(true)}
                className="text-primary mg-l-20"
              >
                <b>Show Link</b>
              </span>
            </div>

            <div className="align-items-center d-flex mg-t-20">
              <span className={`btn btn-primary border-rounded mg-r-20`}>
                2
              </span>
              <span className="text-muted font-weight-bold">
                Use MFA application or your device camera to scan QR code.
              </span>
              <span
                onClick={handleChangeDevice}
                className="text-primary mg-l-20"
              >
                <b>Show QR Code</b>
              </span>
            </div>
            <div className="row">
              <div className="col-12">
                <img
                  height={200}
                  width={200}
                  className="cursor-pointer"
                  src={linkImageQR}
                  alt=""
                />
              </div>
            </div>
            <div className="align-items-center d-flex mg-t-20">
              <span className={`btn btn-primary border-rounded mg-r-20`}>
                3
              </span>
              <span className="text-muted font-weight-bold">
                Input the verify code (6 digits) to below box
              </span>
            </div>
            <div className="row mg-t-20">
              <div className="col-12">
                <input
                  placeholder="Verify code"
                  type="number"
                  className={`form-control form-control-solid h-auto py-5 px-6 `}
                  onChange={(e) => setOtpCode1(e.target.value)}
                />
              </div>
              <div className="col-12">
                <button
                  type="button"
                  className={`btn btn-light btn-elevate font-weight-bold px-9 py-4 my-3 mg-r-20`}
                  onClick={() => setIsOTP("login")}
                >
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-arrow-left-circle"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z"
                      />
                    </svg>
                  </span>
                </button>
                <button
                  id="kt_login_signin_submit"
                  type="button"
                  className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
                  onClick={handleConfirmOTP}
                >
                  <span>Verify</span>
                  {loading && (
                    <span className="ml-3 spinner spinner-white"></span>
                  )}
                </button>
              </div>
            </div>
          </>
        )}

        {isOTP === "login" && (
          <p className="text-muted font-weight-bold">{t("login_enter")}</p>
        )}
        {isOTP === "otp" && (
          <p className="text-muted font-weight-bold">
            Please open Google Authentication and enter OTP
          </p>
        )}
      </div>
      {/* end::Head */}

      {/*begin::Form*/}
      {isOTP === "otp" && (
        <>
          <div className="form-group fv-plugins-icon-container">
            <input
              placeholder="Enter OTP"
              type="number"
              className={`form-control form-control-solid h-auto py-5 px-6`}
              name="otp"
              onKeyDown={handleKeyDown}
              onChange={(e) => {
                setOtpCode(e.target.value);
              }}
            />
          </div>
          <div className="form-group d-flex flex-wrap justify-content-center align-items-center">
            <button
              type="button"
              className={`btn btn-light btn-elevate font-weight-bold px-9 py-4 my-3 mg-r-20`}
              onClick={() => setIsOTP("login")}
            >
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-arrow-left-circle"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z"
                  />
                </svg>
              </span>
            </button>
            <button
              id="kt_login_signin_submit"
              type="button"
              disabled={loading}
              className={`btn btn-info font-weight-bold px-9 py-4 my-3 mg-r-20`}
              onClick={() => {
                setIsOTP("qr");
                setLinkImageQR("/media/nodata2.png");
              }}
            >
              <span>Change Device</span>
              {loading && <span className="ml-3 spinner spinner-white"></span>}
            </button>
            <button
              id="kt_login_signin_submit"
              type="button"
              disabled={loading}
              className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
              onClick={handleLoginStep2}
            >
              <span>Sign In</span>
              {loading && <span className="ml-3 spinner spinner-white"></span>}
            </button>
          </div>
        </>
      )}
      <form
        onSubmit={formik.handleSubmit}
        className="form fv-plugins-bootstrap fv-plugins-framework"
      >
        {isOTP === "login" && (
          <>
            <div className="form-group fv-plugins-icon-container">
              <input
                placeholder="ID (e-mail)"
                type="email"
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  "email"
                )}`}
                name="email"
                {...formik.getFieldProps("email")}
              />
              {formik.touched.email && formik.errors.email ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.email}</div>
                </div>
              ) : null}
            </div>
            <div className="form-group fv-plugins-icon-container">
              <input
                placeholder="Password"
                type="password"
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  "password"
                )}`}
                name="password"
                {...passwordForm}
              />
              {formik.touched.password && formik.errors.password ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.password}</div>
                </div>
              ) : null}
            </div>

            <div className="form-check form-check-custom form-check-solid me-10 ">
              <input
                className="form-check-input h-20px w-20px cursor-pointer"
                type="checkbox"
                defaultChecked={remember}
                onChange={() => setRemember(!remember)}
                id="save_id"
              />
              <label
                className="form-check-label mg-l-15 mg-t-5 cursor-pointer"
                htmlFor="save_id"
              >
                {t("login_save_id")}
              </label>
            </div>

            <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
              <Link
                to="/auth/change-password"
                className="text-dark-50 text-hover-primary my-3 mr-2"
                id="kt_login_forgot"
              >
                {t("login_forgot_password")}
              </Link>

              <button
                id="kt_login_signin_submit"
                type="submit"
                disabled={formik.isSubmitting}
                className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
              >
                <span>Sign In</span>
                {loading && (
                  <span className="ml-3 spinner spinner-white"></span>
                )}
              </button>
            </div>
            <label>
              * For inquiries
              <a
                className="cursor-pointer text-hover-primary"
                href="mailto:admin@butteryum.net"
              >
                admin@butteryum.net
              </a>
              Please contact us
            </label>
            <label>{t("login_recommend_using")}</label>
          </>
        )}
      </form>
      <ConfirmModal
        show={isLinkApp}
        onHide={() => setLinkApp(false)}
        header="Link Down Google Authentication"
        title={
          <div className="row">
            <div className="col-6">
              <img
                className="cursor-pointer"
                src="/media/ios.png"
                width={200}
                alt=""
              />
              <p className="mg-t-10">IOS</p>
            </div>
            <div className="col-6">
              <img
                className="cursor-pointer"
                src="/media/android.png"
                width={200}
                alt=""
              />
              <p className="mg-t-10">Android</p>
            </div>
          </div>
        }
        content={content}
        yesButton="Confirm"
        yesAction={() => setLinkApp(false)}
      />
      {/*end::Form*/}
      <IncorrectLoginModal
        show={isDisplayIncorrectModal}
        onHide={() => setDisplayIncorrectModal(false)}
        count={+currentCount}
        onChangePasswordHandle={onChangePasswordHandle}
      />

      <ConfirmModal
        show={isDisplayChangePwGuildModal}
        onHide={() => setDisplayChangePwGuildModal(false)}
        header="Change Password"
        title="Change your password periodically. Keep your personal information safe"
        subContent="Admin should change new password every 3 months to log in to system"
        yesButton="Change Password"
        yesAction={() => {
          setDisplayChangePwGuildModal(false);
          onChangePasswordHandle();
        }}
      />

      <ConfirmModal
        show={isDisplayUnlockAccModal}
        onHide={() => setDisplayUnlockAccModal(false)}
        header="Notice"
        imgSrc="/media/logos/unlock-icon.ico"
        title="Your account has not log in for 3 months It is temporarily locked."
        content="If you want to use the manager normally please unlock."
        yesButton="Unlock account"
        yesAction={() => {
          setDisplayUnlockAccModal(false);
          onChangePasswordHandle("unlock");
        }}
      />
      <ConfirmModal
        show={isDisplayUnavaliableModal}
        onHide={() => setDisplayUnavaliableModal(false)}
        header="Notice"
        title="Currently unavailable."
        content="Cannot log in to the system."
        yesButton="Confirm"
        yesAction={() => setDisplayUnavaliableModal(false)}
      />

      <ConfirmModal
        show={isLockedModal}
        onHide={() => setLockedModal(false)}
        header="Notice"
        title="Admin user login locked."
        content="You have got the limit of password. Your account is locked. Please contact Admin for unlock account"
        yesButton="Confirm"
        yesAction={() => setLockedModal(false)}
      />
      <ConfirmModal
        show={isOTPModal}
        onHide={() => setOTPModal(false)}
        header="Notice"
        title="OTP ERROR"
        content={content}
        yesButton="Confirm"
        yesAction={() => setOTPModal(false)}
      />
      <ConfirmModal
        show={isLockedModal}
        onHide={() => setLockedModal(false)}
        header="Notice"
        title="Admin user login locked."
        content="You have got the limit of password. Your account is locked. Please contact Admin for unlock account"
        yesButton="Confirm"
        yesAction={() => setLockedModal(false)}
      />
      <ConfirmModal
        show={isDisplayDeletedAccModal}
        onHide={() => setDisplayDeletedAccModal(false)}
        header="Notice"
        title="This account is deleted."
        content="The current ID is the deleted ID. Can't access as an Admin."
        subContent="If you are a normally available Administrator. Please contact the
        Super Manager."
        yesButton="Confirm"
        yesAction={() => setDisplayDeletedAccModal(false)}
      />

      <ConfirmModal
        show={isDisplayForceLoginModal}
        onHide={() => setDisplayForceLoginModal(false)}
        header="Notice"
        title={t("login_your_account")}
        content={`${dataMultipleAccess?.ip} ${t("")}`}
        subContent={t("login_if_it_is_correct")}
        yesButton="Confirm"
        yesAction={() => {
          setDisplayForceLoginModal(false);
          formik.handleSubmit();
        }}
        cancelButton="Cancel"
        cancelAction={() => setDisplayForceLoginModal(false)}
      />
    </div>
  );
}

export default connect(null, auth.actions)(Login);
