import ApiService from "../../utils/ApiService";
export const SPLASH_PREFIX = `${process.env.REACT_APP_API_URL}/splash`;
export const UPLOAD_PREFIX = `${process.env.REACT_APP_API_URL}/upload`;

export const uploadFiles = (formData) => {
  return ApiService.post(UPLOAD_PREFIX, formData);
};

export const createSplash = (body) => {
  return ApiService.post(SPLASH_PREFIX, body);
};

export const getSplashes = (params) => {
  return ApiService.get(`${SPLASH_PREFIX}?${params}`);
};

export const getSplashDetails = (id) => {
  return ApiService.get(`${SPLASH_PREFIX}/${id}`);
};

export const updateSplashDetails = (id, body) => {
  return ApiService.put(`${SPLASH_PREFIX}/${id}`, body);
};

export const deleteSplash = (id) => {
  return ApiService.delete(`${SPLASH_PREFIX}/${id}`);
};

export const bulkDeleteSplashes = (body) => {
  return ApiService.post(`${SPLASH_PREFIX}/bulkdelete`, body);
};
