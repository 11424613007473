import { createSlice } from '@reduxjs/toolkit';
import * as weeklyActions from './weeklyActions';
const initialWeeklyState = {
  entities: null,
  listVideos: null
};
export const callTypes = {
  list: 'list',
  action: 'action'
};

export const weeklySlice = createSlice({
  name: 'Weekly',
  initialState: initialWeeklyState,
  extraReducers: {
    [weeklyActions.fetchWeeklies.fulfilled]: (state, action) => {
      state.entities = action?.payload;
    },
    [weeklyActions.searchVideos.fulfilled]: (state, action) => {
      state.listVideos = action?.payload;
    }
  }
});
