import { createSlice } from "@reduxjs/toolkit";

const initialNewsLetterState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  entitiesPreview: null,
  newsletterForEdit: undefined,
  lastError: null,
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const newsletterSlice = createSlice({
  name: "NewsLetter",
  initialState: initialNewsLetterState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listPreviewLoading = true;
        state.listUpdateLoading = true;
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
        state.listPreviewLoading = true;
        state.listUpdateLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },

    endCall: (state) => {
      state.error = null;
      state.listLoading = false;
      state.listPreviewLoading = false;
      state.listUpdateLoading = false;
      state.actionsLoading = false;
    },

    // get newsletter ById
    newsletterFetched: (state, action) => {
      state.actionsLoading = false;
      state.newsletterForEdit = action.payload.newsletterForEdit;
      state.error = null;
      state.actionsLoading = false;
    },
    // find newsletter
    newslettersFetched: (state, action) => {
      const { data, total } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = data;
      state.totalCount = total;
      state.actionsLoading = false;
    },

    newslettersUpdateBulk: (state, action) => {
      state.listLoading = false;
      state.actionsLoading = false;
      state.listUpdateLoading = false;
      state.error = null;
    },
    // create newsletter
    newsletterCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
    },
    // update newsletter
    newsletterUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
    },
    // delete newsletter
    newsletterDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
    },
    // delete newsletter
    newslettersDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
    },
    // newsletterUpdateState
    newsletterStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
    },
  },
});
