import { createSlice } from "@reduxjs/toolkit";

const initialGroupState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  flyerTotalCount: 0,
  entities: null,
  flyerEntities: null,
  entitiesPreview: null,
  groupForEdit: undefined,
  flyerForEdit: undefined,
  lastError: null,
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const groupSlice = createSlice({
  name: "store",
  initialState: initialGroupState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      state.actionsLoading = false;
      state.listLoading = false;
    },
    startCall: (state, action) => {
      state.error = null;
      state.listLoading = true;
      state.actionsLoading = true;
    },
    endCall: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.listLoading = false;
    },
    // get group ById
    groupFetched: (state, action) => {
      state.listLoading = false;
      state.groupForEdit = action.payload.groupForEdit;
      state.error = null;
      state.actionsLoading = false;
    },
    flyerFetched: (state, action) => {
      state.listLoading = false;
      state.flyerForEdit = action.payload.flyerForEdit;
      state.error = null;
      state.actionsLoading = false;
    },
    // find group
    groupsFetched: (state, action) => {
      const entities = action.payload.stores;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = action.payload.total;
      state.actionsLoading = false;
      state.groupForEdit = undefined;
    },
    // find group
    flyersFetched: (state, action) => {
      const entities = action.payload.flyers;
      state.flyerForEdit = undefined;
      state.listLoading = false;
      state.error = null;
      state.flyerEntities = entities;
      state.flyerTotalCount = action.payload.total;
      state.actionsLoading = false;
    },
    //
    groupsPreviewFetched: (state, action) => {
      const { entities } = action.payload;
      state.listLoading = false;
      state.actionsLoading = false;
      state.error = null;
      state.entitiesPreview = entities;
    },
    // create group
    groupCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.group);
    },
    // update group
    groupUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map((entity) => {
        if (entity.id === action.payload.group.id) {
          return action.payload.group;
        }
        return entity;
      });
    },
    // delete group
    groupDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => el.id !== action.payload.id
      );
    },
    // delete group
    groupsDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => !action.payload.ids.includes(el.id)
      );
    },
    // groupUpdateState
    groupStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { ids, status } = action.payload;
      state.entities = state.entities.map((entity) => {
        if (ids.findIndex((id) => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    },
  },
});
