import React, { useMemo } from "react";
import Select from "react-select";
import { FieldFeedbackLabel } from "./FieldFeedbackLabel";

export function SelectField(props) {
  const {
    withFeedbackLabel = true,
    placeholder,
    disabled,
    customFeedbackLabel,
    options = [],
    label,
    isMulti = false,
    field,
    keyLabel = "label",
    keyValue = "value",
    form: { touched, errors },
    maxOptions = 20,
    className,
    onChangeCallback = () => {},
  } = props;

  const { name = "", value = "" } = field;

  const handleSelectedOptionChange = (selectedOption) => {
    const changeEvent = {
      target: {
        name: name,
        value: selectedOption,
      },
    };
    field.onChange(changeEvent);
    onChangeCallback(selectedOption);
  };

  const dataValue = useMemo(() => {
    if (value?.constructor !== String && value?.constructor !== Number)
      return value;
    const selectedOption =
      options.find((option) => option[keyValue] + "" === value + "") || null;
    return selectedOption;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);
  return (
    <div>
      {label && (
        <label className="font-bold">
          {label} {`${withFeedbackLabel ? " (*)" : ""}`}
        </label>
      )}
      <Select
        id={name}
        {...field}
        value={dataValue}
        onChange={handleSelectedOptionChange}
        placeholder={placeholder}
        isDisabled={disabled}
        options={dataValue?.length >= maxOptions ? [] : options}
        isMulti={isMulti}
        getOptionLabel={(option) => option[keyLabel]}
        getOptionValue={(option) => option[keyValue]}
        className={className}
        styles={{
          menu: (provided) => ({ ...provided, zIndex: 9999 }),
        }}
      />
      {withFeedbackLabel && (
        <FieldFeedbackLabel
          error={errors[field.name]}
          touched={touched[field.name]}
          label={label}
          customFeedbackLabel={customFeedbackLabel}
        />
      )}
    </div>
  );
}
