import React from "react";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { useFormikContext } from "formik";
import moment from "moment";

export function DateRangerPickerField(props) {
    const { setFieldValue, errors, touched } = useFormikContext();

    const {
        initialSettings,
        className,
        placeholder,
        field,
        withFeedbackLabel,
        dateRangerPickerRef,
        inputRef,
        disabled,
        label,
        key,
        isReadOnly = false,
    } = props;
    const { name = "", value } = field;
    const {
        locale: { format = "MM/DD/YYYY" },
        minDate,
        maxDate,
    } = initialSettings;

    const handleApply = (event, picker) => {
        if (disabled !== true) {
            const value = `${picker.startDate.format(format)} - ${picker.endDate.format(format)}`;
            if (inputRef?.current?.value) {
                inputRef.current.value = value;
            }
            setFieldValue(name, value);
        }
    };

    const handleCancel = (event, picker) => {
        if (disabled !== true) {
            if (inputRef?.current?.value) {
                inputRef.current.value = "";
            }
            setFieldValue(name, "");
        }
    };

    const onBlur = (e) => {
        try {
            let value = "";

            const dates = e.target.value.split(" - ");
            if (moment(dates[0]).isValid() && moment(dates[1]).isValid()) {
                const start = moment(dates[0]);
                const end = moment(dates[1]);
                if (
                    // have minDate & maxDate
                    (minDate &&
                        moment(minDate).isValid() &&
                        maxDate &&
                        moment(maxDate).isValid() &&
                        moment(maxDate) > end &&
                        end > start &&
                        start > moment(minDate)) ||
                    // have minDate
                    (minDate &&
                        moment(minDate).isValid() &&
                        end > start &&
                        start > moment(minDate)) ||
                    // have maxDate
                    (maxDate &&
                        moment(maxDate).isValid() &&
                        moment(maxDate) > end &&
                        end > start) ||
                    // don't have minDate, maxDate
                    (!minDate && !maxDate && end > start)
                ) {
                    value = `${moment(dates[0]).format(format)} - ${moment(dates[1]).format(
                        format
                    )}`;
                }
            }
            if (inputRef?.current?.value) {
                inputRef.current.value = value;
            }
            setFieldValue(name, value);
        } catch (error) {
            if (inputRef?.current?.value) {
                inputRef.current.value = "";
            }
            setFieldValue(name, "");
        }
    };

    return (
        <div>
            {label && <label>{label}</label>}
            <DateRangePicker
                key={key}
                initialSettings={initialSettings}
                onApply={handleApply}
                onCancel={handleCancel}
                ref={dateRangerPickerRef}
            >
                <div className={`input-group ${className}`}>
                    <input
                        type="text"
                        className="form-control"
                        placeholder={placeholder}
                        disabled={disabled}
                        ref={inputRef}
                        onBlur={onBlur}
                        readOnly={isReadOnly}
                        value={value}
                    />
                    <div className="input-group-append">
                        <span className="input-group-text">
                            <i className="fas fa-calendar-check"></i>
                        </span>
                    </div>
                </div>
            </DateRangePicker>
            {withFeedbackLabel && errors[field.name] && touched[field.name] && (
                <div className="invalid-feedback">{errors[field.name]}</div>
            )}
        </div>
    );
}
