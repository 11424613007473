import { createSlice } from "@reduxjs/toolkit";

const initialPopupState = {
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    entities: null,
    entitiesPreview: null,
    popupForEdit: undefined,
    lastError: null,
};
export const callTypes = {
    list: "list",
    action: "action",
};

export const popupSlice = createSlice({
    name: "Popup",
    initialState: initialPopupState,
    reducers: {
        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.error}`;
            if (action.payload.callType === callTypes.list) {
                state.listPreviewLoading = true;
                state.listUpdateLoading = true;
                state.listLoading = false;
            } else {
                state.actionsLoading = false;
            }
        },
        startCall: (state, action) => {
            state.error = null;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = true;
                state.listPreviewLoading = true;
                state.listUpdateLoading = true;
            } else {
                state.actionsLoading = true;
            }
        },

        endCall: (state) => {
            state.error = null;
            state.listLoading = false;
            state.listPreviewLoading = false;
            state.listUpdateLoading = false;
            state.actionsLoading = false;
        },

        // get popup ById
        popupFetched: (state, action) => {
            state.actionsLoading = false;
            state.popupForEdit = action.payload.popupForEdit;
            state.error = null;
            state.actionsLoading = false;
        },
        // find popup
        popupsFetched: (state, action) => {
            const { pop_ups, total } = action.payload;
            state.listLoading = false;
            state.error = null;
            state.entities = pop_ups;
            state.totalCount = total;
            state.actionsLoading = false;
        },

        popupsUpdateBulk: (state, action) => {
            state.listLoading = false;
            state.actionsLoading = false;
            state.listUpdateLoading = false;
            state.error = null;
        },
        // create popup
        popupCreated: (state, action) => {
            state.actionsLoading = false;
            state.error = null;
        },
        // update popup
        popupUpdated: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
        },
        // delete popup
        popupDeleted: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
        },
        // delete popup
        popupsDeleted: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
        },
        // popupUpdateState
        popupStatusUpdated: (state, action) => {
            state.actionsLoading = false;
            state.error = null;
        },
    },
});
