import * as auth from "app/modules/Auth/_redux/authRedux";
import { dashboardSlice } from "app/modules/Dashboard/redux/dashboardSlice";
import { loadingSlice } from "app/modules/Loading/Redux/LoadingSlice";
import { policySlice } from "app/modules/TermCondition/redux/policySlice";
import { toastSlice } from "app/modules/Toast/Redux/ToastSlice";
import { usersSlice } from "app/modules/UsersManagement/_redux/User/usersSlice";
import { bannerSlice } from "Banner/_redux/bannerSlice";
import { categorySlice } from "Category/_redux/categorySlice";
import { commentSlice } from "Comment/_redux/commentSlice";
import { creatorSlice } from "Creator/_redux/creatorSlice";
import { groupSlice } from "GroupIngredient/_redux/groupSlice";
import { historySlice } from "History/_redux/historySlice";
import { ingredientSlice } from "Ingredient/_redux/ingredientSlice";
import { get } from "lodash";
import { memoSlice } from "Memo/_redux/memoSlice";
import { notificationSlice } from "Notification/_redux/notificationSlice";
import { pollSlice } from "Poll/_redux/pollSlice";
import { popupSlice } from "Popup/_redux/popupSlice";
import { pushSlice } from "Push/redux/pushSlice";
import { productSlice } from "Product/_redux/productSlice";
import createTransform from "redux-persist/lib/createTransform";
import persistCombineReducers from "redux-persist/lib/persistCombineReducers";
import storage from "redux-persist/lib/storage";
import { all } from "redux-saga/effects";
import { groupSlice as storeSlice } from "Store/_redux/groupSlice";
import { tagSlice } from "Tag/_redux/tagSlice";
import { themeSlice } from "Theme/_redux/themeSlice";
import { videoSlice } from "Video/_redux/videoSlice";
import { weeklySlice } from "Weekly/_redux/weeklySlice";
import { curatedSlice } from "Curated/_redux/curatedSlice";
import { curatedPostSlice } from "CuratedPost/_redux/curatedPostSlice";
import { introSlice } from "Introduce/_redux/introSlice";
import { groupSlice as questionSlice } from "Question/_redux/groupSlice";
import { newsletterSlice } from "NewsLetter/_redux/newsLetterSlice";
import { creatorblogSlice } from "CreatorBlog/_redux/creatorBlogSlice";
import { blogSlice } from "Blog/_redux/blogSlice";
import { badgeSlice } from "Badge/_redux/badgeSlice";
import { pinSlice } from "PinManagement/_redux/pinSlice";
import { curatedIngredientSlice } from "CuratedIngredient/_redux/curatedIngredientSlice";
import { splashSlice } from "Splash/_redux/splashSlice";

let whitelistTransform = createTransform((inboundState, key) => {
  if (key === "auth") {
    return { authToken: get(inboundState, "authToken") };
  } else if (key === "push") {
    return { filterPush: get(inboundState, "filterPush") };
  }
});

const persistConfig = {
  key: "v236-recipe-app",
  storage,
  whitelist: [`auth`, "push"],
  transforms: [whitelistTransform],
};
export const rootReducer = persistCombineReducers(persistConfig, {
  auth: auth.reducer,
  users: usersSlice.reducer,
  category: categorySlice.reducer,
  theme: themeSlice.reducer,
  tag: tagSlice.reducer,
  question: questionSlice.reducer,
  ingredient: ingredientSlice.reducer,
  group: groupSlice.reducer,
  store: storeSlice.reducer,
  video: videoSlice.reducer,
  creator: creatorSlice.reducer,
  memo: memoSlice.reducer,
  history: historySlice.reducer,
  weekly: weeklySlice.reducer,
  curated: curatedSlice.reducer,
  curatedPost: curatedPostSlice.reducer,
  intro: introSlice.reducer,
  notification: notificationSlice.reducer,
  push: pushSlice.reducer,
  product: productSlice.reducer,
  popup: popupSlice.reducer,
  loading: loadingSlice.reducer,
  toast: toastSlice.reducer,
  policy: policySlice.reducer,
  banner: bannerSlice.reducer,
  poll: pollSlice.reducer,
  comment: commentSlice.reducer,
  dashboard: dashboardSlice.reducer,
  newsletter: newsletterSlice.reducer,
  creatorblog: creatorblogSlice.reducer,
  blog: blogSlice.reducer,
  badge: badgeSlice.reducer,
  pin: pinSlice.reducer,
  curatedIngredient: curatedIngredientSlice.reducer,
  splash: splashSlice.reducer,
});

export function* rootSaga() {
  yield all([auth.saga()]);
}
