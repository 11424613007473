import { createSlice } from "@reduxjs/toolkit";

const initialCreatorBlogState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  entitiesPreview: null,
  creatorblogForEdit: undefined,
  lastError: null,
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const creatorblogSlice = createSlice({
  name: "CreatorBlog",
  initialState: initialCreatorBlogState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    endCall: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.listLoading = false;
    },
    // get creatorblog ById
    creatorblogFetched: (state, action) => {
      state.actionsLoading = false;
      state.creatorblogForEdit = action.payload?.creatorblogForEdit?.data;
      state.error = null;
      state.actionsLoading = false;
    },
    // find creatorblog
    creatorblogsFetched: (state, action) => {
      const entities = action.payload.data;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = action.payload.pagination.count;
      state.actionsLoading = false;
    },
    //
    creatorblogsPreviewFetched: (state, action) => {
      const { entities } = action.payload;
      state.listLoading = false;
      state.actionsLoading = false;
      state.error = null;
      state.entitiesPreview = entities;
    },
    // create creatorblog
    creatorblogCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.creatorblog);
    },
    // update creatorblog
    creatorblogUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map((entity) => {
        if (entity.id === action.payload.creatorblog.id) {
          return action.payload.creatorblog;
        }
        return entity;
      });
    },
    // delete creatorblog
    creatorblogDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => el.id !== action.payload.id
      );
    },
    // delete creatorblog
    creatorblogsDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => !action.payload.ids.includes(el.id)
      );
    },
    // creatorblogUpdateState
    creatorblogStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { ids, status } = action.payload;
      state.entities = state.entities.map((entity) => {
        if (ids.findIndex((id) => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    },
  },
});
