import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
    createEditGroupUserConditionAPI,
    createEditGroupUserIdAPI,
    createEditGroupUserIdAPIExcel,
    createPushAPI,
    deleteGroupUserAPI,
    deleteMultipleGroupUserAPI,
    deleteMultiplePushAPI,
    deletePushAPI,
    DownloadFileAPI,
    DownloadFileSample,
    EditPushAPI,
    GetDetailGroupUserAPI,
    getDetailPushAPI,
    getListFilterAPI,
    getListPushAPI,
    getListPushGroupAPI,
    searchUserAPI,
} from "./pushCrud";
import { get } from "lodash";
import { parseErrorToHandle } from "app/modules/utils/requestUtils";

const initialPushState = {
    pushList: [],
    totalCount: 0,
    filterPush: {},
    detailPush: {},

    pushGroupList: [],
    totalPushGroupCount: 0,
    userList: [],
    totalUserCount: 0,
    detailGroupUser: {},
};

// **notification push**//
export const getListPush = createAsyncThunk(
    "push/getListPush",
    async (params, { rejectWithValue }) => {
        try {
            const response = await getListPushAPI(params);
            return response;
        } catch (error) {
            return rejectWithValue(parseErrorToHandle(error));
        }
    },
);

export const getFilterPush = createAsyncThunk(
    "push/getFilterPush",
    async (_, { rejectWithValue }) => {
        try {
            const response = await getListFilterAPI();
            return response;
        } catch (error) {
            return rejectWithValue(parseErrorToHandle(error));
        }
    },
);

export const createPush = createAsyncThunk(
    "push/createPush",
    async (params, { rejectWithValue }) => {
        try {
            const data = await createPushAPI(params);
            return { data, message: "Create a new push notification successfully" };
        } catch (error) {
            return rejectWithValue(parseErrorToHandle(error));
        }
    },
);

export const getDetailPush = createAsyncThunk(
    "push/getDetailPush",
    async (params, { rejectWithValue }) => {
        try {
            const response = await getDetailPushAPI(params);
            return response;
        } catch (error) {
            return rejectWithValue(parseErrorToHandle(error));
        }
    },
);

export const EditPush = createAsyncThunk(
    "push/EditPush",
    async ({ id, params }, { rejectWithValue }) => {
        try {
            const data = await EditPushAPI(id, params);
            return { data, message: "Edit push notification successfully" };
        } catch (error) {
            return rejectWithValue(parseErrorToHandle(error));
        }
    },
);

export const deletePush = createAsyncThunk(
    "push/deletePush",
    async (params, { rejectWithValue }) => {
        try {
            const data = await deletePushAPI(params);
            return { data, message: "Delete push notification successfully" };
        } catch (error) {
            return rejectWithValue(parseErrorToHandle(error));
        }
    },
);

export const deleteMultiplePush = createAsyncThunk(
    "push/deleteMultiplePush",
    async (params, { rejectWithValue }) => {
        try {
            const data = await deleteMultiplePushAPI(params);
            return { data, message: "Delete multiple push notifications successfully" };
        } catch (error) {
            return rejectWithValue(parseErrorToHandle(error));
        }
    },
);

// **notification group push**//
export const getListPushGroup = createAsyncThunk(
    "push/getListPushGroup",
    async (params, { rejectWithValue }) => {
        try {
            const response = await getListPushGroupAPI(params);
            return response;
        } catch (error) {
            return rejectWithValue(parseErrorToHandle(error));
        }
    },
);

export const getDetailGroupUser = createAsyncThunk(
    "push/getDetailGroupUser",
    async (params, { rejectWithValue }) => {
        try {
            const response = await GetDetailGroupUserAPI(params);
            return response;
        } catch (error) {
            return rejectWithValue(parseErrorToHandle(error));
        }
    },
);

export const searchUser = createAsyncThunk(
    "push/searchUser",
    async (params, { rejectWithValue }) => {
        try {
            const response = await searchUserAPI(params);
            return response;
        } catch (error) {
            return rejectWithValue(parseErrorToHandle(error));
        }
    },
);

export const createEditGroupUserCondition = createAsyncThunk(
    "push/createEditGroupUserCondition",
    async (params, { rejectWithValue }) => {
        try {
            const data = await createEditGroupUserConditionAPI(params);
            if (get(params, "push_group_id")) {
                return { data, message: "Edit group users successfully" };
            } else {
                return { data, message: "Create group users successfully" };
            }
        } catch (error) {
            return rejectWithValue(parseErrorToHandle(error));
        }
    },
);

export const createEditGroupUserId = createAsyncThunk(
    "push/createEditGroupUserId",
    async (params, { rejectWithValue }) => {
        try {
            const data = await createEditGroupUserIdAPI(params);
            if (get(params, "push_group_id")) {
                return { data, message: "Edit group users successfully" };
            } else {
                return { data, message: "Create group users successfully" };
            }
        } catch (error) {
            return rejectWithValue(parseErrorToHandle(error));
        }
    },
);
export const createEditGroupUserIdByExcel = createAsyncThunk(
    "push/createEditGroupUserIdByExcel",
    async (params, { rejectWithValue }) => {
        try {
            const data = await createEditGroupUserIdAPIExcel(params);
            if (get(params, "push_group_id")) {
                return { data, message: "Edit group users successfully" };
            } else {
                return { data, message: "Create group users successfully" };
            }
        } catch (error) {
            return rejectWithValue(parseErrorToHandle(error));
        }
    },
);

export const deleteGroupUser = createAsyncThunk(
    "push/deleteGroupUser",
    async (params, { rejectWithValue }) => {
        try {
            const data = await deleteGroupUserAPI(params);
            return { data, message: "Delete group users successfully" };
        } catch (error) {
            return rejectWithValue(parseErrorToHandle(error));
        }
    },
);

export const deleteMultipleGroupUser = createAsyncThunk(
    "push/deleteMultipleGroupUser",
    async (params, { rejectWithValue }) => {
        try {
            const data = await deleteMultipleGroupUserAPI(params);
            return { data, message: "Delete multiple group users successfully" };
        } catch (error) {
            return rejectWithValue(parseErrorToHandle(error));
        }
    },
);

export const downloadFileGroupUser = createAsyncThunk(
    "push/downloadFileGroupUser",
    async (params, { rejectWithValue }) => {
        try {
            const data = await DownloadFileAPI(params);
            const a = document.createElement("a");
            a.href = "data:attachment/csv," + encodeURI(data);
            a.target = "_blank";
            a.download = "myFile.csv";
            document.body.appendChild(a);
            a.click();
            return { data, message: "Download file successfully" };
        } catch (error) {
            return rejectWithValue(parseErrorToHandle(error));
        }
    },
);
export const downloadFileGroupUserExample = createAsyncThunk(
    "push/downloadFileGroupUserExample",
    async (params, { rejectWithValue }) => {
        try {
            const data = await DownloadFileSample(params);
            const a = document.createElement("a");
            a.href = "data:attachment/csv," + encodeURI(data);
            a.target = "_blank";
            a.download = "myFile.xsc";
            document.body.appendChild(a);
            a.click();
            return { data, message: "Download file successfully" };
        } catch (error) {
            return rejectWithValue(parseErrorToHandle(error));
        }
    },
);
export const pushSlice = createSlice({
    name: "Push",
    initialState: initialPushState,
    reducers: {
        clearDetailPush: (state, action) => {
            state.detailPush = {};
        },
        clearDetailGroupUser: (state, action) => {
            state.detailGroupUser = {};
        },
    },
    extraReducers: {
        // **notification push**//
        [getListPush.fulfilled]: (state, action) => {
            const { data, total } = action.payload;
            state.pushList = data;
            state.totalCount = total;
        },
        [getListPush.rejected]: (state, action) => {
            state.pushList = [];
            state.totalCount = 0;
        },

        [getFilterPush.fulfilled]: (state, action) => {
            state.filterPush = action.payload;
        },
        [getFilterPush.rejected]: (state, action) => {
            state.filterPush = {};
        },

        [getDetailPush.fulfilled]: (state, action) => {
            state.detailPush = action.payload;
        },
        [getDetailPush.rejected]: (state, action) => {
            state.detailPush = {};
        },

        // **notification push group**//
        [getListPushGroup.fulfilled]: (state, action) => {
            const { data, total } = action.payload;
            state.pushGroupList = data;
            state.totalPushGroupCount = total;
        },
        [getListPushGroup.rejected]: (state, action) => {
            state.pushGroupList = [];
            state.totalPushGroupCount = 0;
        },

        [getDetailGroupUser.fulfilled]: (state, action) => {
            state.detailGroupUser = action.payload;
        },
        [getDetailGroupUser.rejected]: (state, action) => {
            state.detailGroupUser = {};
        },

        [searchUser.fulfilled]: (state, action) => {
            const { data, total } = action.payload;
            state.userList = data;
            state.totalUserCount = total;
        },
        [searchUser.rejected]: (state, action) => {
            state.userList = [];
            state.totalUserCount = 0;
        },
    },
});

export const { clearDetailPush, clearDetailGroupUser } = pushSlice.actions;

//**notification push**//
export const selectPushFilter = (state) => state.push.filterPush;
export const selectPushList = (state) => state.push.pushList;
export const selectPushTotal = (state) => state.push.totalCount;
export const selectPushDetail = (state) => state.push.detailPush;
//**notification push group**//
export const selectPushGroupList = (state) => state.push.pushGroupList;
export const selectPushGroupTotal = (state) => state.push.totalPushGroupCount;
export const selectUserList = (state) => state.push.userList;
export const selectUserTotal = (state) => state.push.totalUserCount;
export const selectDetailGroup = (state) => state.push.detailGroupUser;
