/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React, { useCallback, useEffect, useState, useRef } from "react";
import {
  Redirect,
  Switch,
  Route,
  useLocation,
  useHistory,
} from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import { Layout } from "_metronic/layout";
import BasePage from "./BasePage";
import { Logout, AuthPage } from "./modules/Auth";
import ErrorsPage from "./modules/ErrorsExamples/ErrorsPage";

export function Routes() {
  const location = useLocation();
  const history = useHistory();

  const timer = useRef(null);

  const { isAuthorized } = useSelector(
    ({ auth }) => ({
      isAuthorized: auth.user != null,
    }),
    shallowEqual
  );

  const [isAuth, setAuth] = useState(isAuthorized);

  const restart = useCallback(() => {
    if (timer.current) {
      clearTimeout(timer.current);
    }

    timer.current = setTimeout(() => {
      history.push("/logout");
    }, 1000 * 60 * 15);
  }, [history]);

  const move = useCallback(() => {
    restart();
  }, [restart]);

  useEffect(() => {
    if (location.pathname !== "/auth/change-password") {
      setAuth(isAuthorized);
    }
  }, [location, isAuthorized]);

  useEffect(() => {
    restart();

    window.addEventListener("mousemove", move);

    return () => {
      if (timer.current) {
        clearTimeout(timer.current);
        window.removeEventListener("mousemove", move);
      }
    };
  }, [location, restart, move]);

  return (
    <Switch>
      {!isAuth ? (
        <Route>
          <AuthPage />
        </Route>
      ) : (
        <Redirect from="/auth" to="/" />
      )}
      <Route path="/error" component={ErrorsPage} />
      <Route path="/logout" component={Logout} />
      {!isAuth ? (
        <Redirect to="/auth/login" />
      ) : (
        <Layout>
          <BasePage />
        </Layout>
      )}
    </Switch>
  );
}
