import { createSlice } from "@reduxjs/toolkit";

const initialThemeState = {
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    entities: null,
    entitiesPreview: null,
    themeForEdit: undefined,
    lastError: null,
};
export const callTypes = {
    list: "list",
    action: "action",
};

export const themeSlice = createSlice({
    name: "Theme",
    initialState: initialThemeState,
    reducers: {
        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.error}`;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false;
            } else {
                state.actionsLoading = false;
            }
        },
        startCall: (state, action) => {
            state.error = null;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = true;
            } else {
                state.actionsLoading = true;
            }
        },

        // get theme ById
        themeFetched: (state, action) => {
            state.actionsLoading = false;
            state.themeForEdit = action.payload;
            state.error = null;
            state.actionsLoading = false;
        },
        // find theme
        themesFetched: (state, action) => {
            const entities = action.payload;
            state.listLoading = false;
            state.error = null;
            state.entities = entities;
            state.actionsLoading = false;
        },
        //
        themesPreviewFetched: (state, action) => {
            const { entities } = action.payload;
            state.listLoading = false;
            state.actionsLoading = false;

            state.error = null;
            state.entitiesPreview = entities;
        },
        // create theme
        themeCreated: (state, action) => {
            state.actionsLoading = false;
            state.error = null;
            state.entities.push(action.payload.theme);
        },
        // update theme
        themeUpdated: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
        },
        // delete theme
        themeDeleted: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
        },
        // delete theme
        themesDeleted: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
            state.entities = state.entities.filter(
                (el) => !action.payload.ids.includes(el.id)
            );
        },
        // themeUpdateState
        themeStatusUpdated: (state, action) => {
            state.actionsLoading = false;
            state.error = null;
        },
    },
});
