import ApiService from '../../utils/ApiService';
export const INTRO_URL = `${process.env.REACT_APP_API_URL}/intros`;
export const UPLOAD_URL = `${process.env.REACT_APP_API_URL}/upload`;

export function uploadFile(formData) {
  return ApiService.post(UPLOAD_URL, formData);
}

export function createIntro(intro) {
  return ApiService.post(INTRO_URL, intro);
}
export function getIntroById() {
  return ApiService.get(`${INTRO_URL}/homepage`);
}
export function updateIntro(intro) {
  return ApiService.put(`${INTRO_URL}/${intro.id}`, intro);
}
