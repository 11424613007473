import React from "react";
import PhoneInput from "react-phone-input-2";
import { FieldFeedbackLabel } from "./FieldFeedbackLabel";
import "react-phone-input-2/lib/style.css";
import {useTranslation} from "react-i18next";

export function PhoneInputHook(props) {
  const {
    withFeedbackLabel = true,
    customFeedbackLabel,
    label,
    field,
    form: { touched, errors },
  } = props;

  const {t} = useTranslation()
  const { name = "", value = "" } = field;

  const handleSelectedOptionChange = (data) => {
    const changeEvent = {
      target: {
        name: name,
        value: data,
      },
    };
    field.onChange(changeEvent);
  };

  return (
    <>
      {label && <label> {label}</label>}
      <PhoneInput
        placeholder={t("placeholder_enter_phone_number")}
        country={"kr"}
        value={value}
        onChange={handleSelectedOptionChange}
        onlyCountries={["kr", "vn"]}
        inputClass="w-100"
      />

      {withFeedbackLabel && (
        <FieldFeedbackLabel
          error={errors[field.name]}
          touched={touched}
          label={label}
          customFeedbackLabel={customFeedbackLabel}
        />
      )}
    </>
  );
}
