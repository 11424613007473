import { createSlice } from "@reduxjs/toolkit";
import useNotyf from "../useNotyf";
import { get } from "lodash";
import { DefaultErrorMsg } from "app/modules/utils/Constant";
import { isFulfilledAction, isRejectedAction } from "app/modules/utils/helper";

const notif = useNotyf();

const module = ["push/", "policy/", "dashboard/", "comment/"];

export const toastSlice = createSlice({
    name: "toast",
    initialState: {},
    reducers: {},
    extraReducers: (builder) => {
        builder.addMatcher(isFulfilledAction(module), (state, action) => {
            if (get(action.payload, "message", "") !== "") {
                notif.success(get(action.payload, "message"));
            }
        });
        builder.addMatcher(isRejectedAction(module), (state, action) => {
            notif.error(get(action.payload, "message[0].messages[0].message", DefaultErrorMsg));
        });
    },
});

export default toastSlice.reducer;
