import React from "react";
import { Modal } from "react-bootstrap";
import { UserEditDialog } from "UsersManagement/pages/Users/components/UserEditDialog";

function ModalEditUser({ show, onHide, id }) {
    return (
        <Modal show={show} onHide={onHide} backdrop="static" backdropClassName="modal-second">
            <Modal.Body className="overlay overlay-block cursor-default">
                <UserEditDialog show={show} onHide={onHide} id={id} typePassword={true} />
            </Modal.Body>
        </Modal>
    );
}

export default ModalEditUser;
