import ApiService from "../../utils/ApiService";
export const POLL_URL = `${process.env.REACT_APP_API_URL}/admin-surveys`;

export const UPLOAD_URL = `${process.env.REACT_APP_API_URL}/upload`;

export function uploadFile(formData) {
  return ApiService.post(UPLOAD_URL, formData);
}

// CREATE =>  POST: add a new Poll to the server
export function createPoll(poll) {
  return ApiService.post(POLL_URL, poll);
}

export function createQuestion(question) {
  return ApiService.post(`${POLL_URL}/questions`, question);
}

export function editQuestion(body) {
  return ApiService.put(`${POLL_URL}/questions/${body.id}`, body);
}

export function createAnswer(answer) {
  return ApiService.post(`${POLL_URL}/answers`, answer);
}
export function editAnswer(body) {
  return ApiService.put(`${POLL_URL}/answers/${body.id}`, body);
}
export function getYoutube(id) {
  return ApiService.get(`${POLL_URL}/youtube/${id}`);
}

export function updatePollsBulk(body) {
  return ApiService.post(`${POLL_URL}/bulkupdate`, body);
}
export function getPollById(pollId) {
  return ApiService.get(`${POLL_URL}/${pollId}`);
}
export function getComments(queryParams) {
  return ApiService.get(
    `${POLL_URL}/comments/${queryParams?.surveyId}`,
    queryParams
  );
}
export function getAnswerId(queryParams) {
  return ApiService.get(
    `${POLL_URL}/answers/users/${queryParams.id}`,
    queryParams
  );
}
export function updateInspection(body) {
  return ApiService.post(`${POLL_URL}/inspection/${body.type}`, body);
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findPolls(queryParams) {
  return ApiService.get(`${POLL_URL}/list`, queryParams);
}
export function findPollsPreview() {
  return ApiService.get(`${POLL_URL}/?_sort=sorting:asc&_where[sorting_gte]=0`);
}
// UPDATE => PUT: update the Poll on the server
export function updatePoll(poll) {
  return ApiService.put(`${POLL_URL}/${poll.id}`, poll);
}

// UPDATE Status
export function updateStatusForPolls(ids, status) {
  return ApiService.post(`${POLL_URL}/updateStatusForPolls`, {
    ids,
    status,
  });
}

// DELETE => delete the Poll from the server
export function deletePoll(pollId) {
  return ApiService.delete(`${POLL_URL}/${pollId}`);
}
export function deleteQuestion(questionId) {
  return ApiService.delete(`${POLL_URL}/questions/${questionId}`);
}
export function deleteAnswer(AnswerId) {
  return ApiService.delete(`${POLL_URL}/answers/${AnswerId}`);
}
// DELETE Polls by ids
export function deletePolls(body) {
  return ApiService.post(`${POLL_URL}/bulkdelete`, body);
}
