import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import SVG from "react-inlinesvg";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import { checkIsActive, toAbsoluteUrl } from "_metronic/_helpers";
import * as actions from "Question/_redux/groupActions";

export function AsideMenuList({ layoutProps }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const location = useLocation();
  const getMenuItemActive = (url, hasSubMenu = false) => {
    return checkIsActive(location, url)
      ? ` ${
          !hasSubMenu && "menu-item-active"
        } menu-item-open menu-item-not-hightlighted`
      : "";
  };
  useEffect(() => {
    dispatch(actions.fetchGroupsUnread({ status_contains: "PENDING" }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const {
    permissionVideoList,
    permissionCreatorList,
    permissionNotificationList,
    permissionBannerList,
    permissionPopupList,
    permissionPushList,
    permissionUserList,
    permissionGroupList,
    permissionCommentList,
    permissionTagList,
    permissionCategoryList,
    permissionGuestList,
    questionUnread,
  } = useSelector(
    (state) => ({
      permissionVideoList: state.auth.groupPermissions?.[`content-video-list`],
      permissionCreatorList:
        state.auth.groupPermissions?.[`content-creator-list`],
      permissionCategoryList:
        state.auth.groupPermissions?.[`content-category-list`],
      permissionTagList: state.auth.groupPermissions?.[`content-tag-list`],
      permissionCommentList: state.auth.groupPermissions?.[`cs-comment-list`],
      permissionNotificationList:
        state.auth.groupPermissions?.[`operation-notification-list`],
      permissionBannerList:
        state.auth.groupPermissions?.[`operation-banner-list`],
      permissionPopupList:
        state.auth.groupPermissions?.[`operation-popup-list`],
      permissionPushList: state.auth.groupPermissions?.[`operation-push-list`],
      permissionUserList: state.auth.groupPermissions?.[`admin-user-list`],
      permissionGuestList: state.auth.groupPermissions?.[`admin-user-guest`],
      permissionGroupList: state.auth.groupPermissions?.[`admin-group-list`],
      questionUnread: state.question.questionUnread,
    }),
    shallowEqual
  );
  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        <li
          className={`menu-item ${getMenuItemActive("/dashboard", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/dashboard">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
            </span>
            <span className="menu-text">{t("menu_dashboard")}</span>
          </NavLink>
        </li>
        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "/introduce",
            true
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/introduce">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Files/File.svg")} />
            </span>
            <span className="menu-text">Intro</span>
          </NavLink>
        </li>
        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "/question",
            true
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/question">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/General/Settings-1.svg")}
              />
            </span>
            <span className="menu-text">
              {t("question")} ({questionUnread})
            </span>
          </NavLink>
        </li>

        <li
          className={`menu-item menu-item-submenu menu-item-open`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/user-management">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/icons/Communication/Add-user.svg"
                )}
              />
            </span>
            <span className="menu-text">{t("menu_admin")}</span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu ">
            <ul className="menu-subnav">
              {permissionUserList && (
                <li
                  className={`menu-item menu-item-submenu ${getMenuItemActive(
                    "user-management/users",
                    true
                  )}`}
                  aria-haspopup="true"
                  data-menu-toggle="hover"
                >
                  <NavLink
                    className="menu-link menu-toggle"
                    to="/user-management/users"
                  >
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">
                      {t("admin_active_lock_admin")}
                    </span>
                  </NavLink>
                </li>
              )}

              {permissionUserList && (
                <li
                  className={`menu-item menu-item-submenu ${getMenuItemActive(
                    "user-management/inactive-users",
                    true
                  )}`}
                  aria-haspopup="true"
                  data-menu-toggle="hover"
                >
                  <NavLink
                    className="menu-link menu-toggle"
                    to="/user-management/inactive-users"
                  >
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">
                      {t("admin_inactive_admin")}
                    </span>
                  </NavLink>
                </li>
              )}
              {permissionGroupList && (
                <li
                  className={`menu-item menu-item-submenu ${getMenuItemActive(
                    "user-management/groups",
                    true
                  )}`}
                  aria-haspopup="true"
                  data-menu-toggle="hover"
                >
                  <NavLink
                    className="menu-link menu-toggle"
                    to="/user-management/groups"
                  >
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">{t("menu_admin_group")}</span>
                  </NavLink>
                </li>
              )}
            </ul>
          </div>
        </li>
        <li
          className={`menu-item menu-item-submenu menu-item-open ${getMenuItemActive(
            "/google-material",
            true
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/google-material">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Shopping/Settings.svg")}
              />
            </span>

            <span className="menu-text">{t("menu_operation")}</span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu ">
            <ul className="menu-subnav">
              {permissionNotificationList && (
                <li
                  className={`menu-item menu-item-submenu ${getMenuItemActive(
                    "/notification",
                    true
                  )}`}
                  aria-haspopup="true"
                  data-menu-toggle="hover"
                >
                  <NavLink className="menu-link menu-toggle" to="/notification">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">{t("menu_notification")}</span>
                  </NavLink>
                </li>
              )}
              {permissionNotificationList && (
                <li
                  className={`menu-item menu-item-submenu ${getMenuItemActive(
                    "/notifications",
                    true
                  )}`}
                  aria-haspopup="true"
                  data-menu-toggle="hover"
                >
                  <NavLink
                    className="menu-link menu-toggle"
                    to="/notifications"
                  >
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Notifications</span>
                  </NavLink>
                </li>
              )}
              {permissionBannerList && (
                <li
                  className={`menu-item menu-item-submenu ${getMenuItemActive(
                    "/banner",
                    true
                  )}`}
                  aria-haspopup="true"
                  data-menu-toggle="hover"
                >
                  <NavLink className="menu-link menu-toggle" to="/banner">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">{t("menu_banner")}</span>
                  </NavLink>
                </li>
              )}
              {permissionPopupList && (
                <li
                  className={`menu-item menu-item-submenu ${getMenuItemActive(
                    "/popup",
                    true
                  )}`}
                  aria-haspopup="true"
                  data-menu-toggle="hover"
                >
                  <NavLink className="menu-link menu-toggle" to="/popup">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">{t("menu_popup")}</span>
                  </NavLink>
                </li>
              )}
              {permissionPushList && (
                <li
                  className={`menu-item menu-item-submenu ${getMenuItemActive(
                    "/push",
                    true
                  )}`}
                  aria-haspopup="true"
                  data-menu-toggle="hover"
                >
                  <NavLink className="menu-link menu-toggle" to="/push">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">{t("menu_push")}</span>
                  </NavLink>
                </li>
              )}

              <li
                className={`menu-item menu-item-submenu ${getMenuItemActive(
                  "/poll",
                  true
                )}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink className="menu-link menu-toggle" to="/poll">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Poll</span>
                </NavLink>
              </li>
              <li
                className={`menu-item menu-item-submenu ${getMenuItemActive(
                  "/store",
                  true
                )}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink className="menu-link menu-toggle" to="/store">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Flyer Store</span>
                </NavLink>
              </li>
              <li
                className={`menu-item menu-item-submenu ${getMenuItemActive(
                  "/newsletter",
                  true
                )}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink className="menu-link menu-toggle" to="/newsletter">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Newsletter</span>
                </NavLink>
              </li>
              <li
                className={`menu-item menu-item-submenu ${getMenuItemActive(
                  "/badge",
                  true
                )}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink className="menu-link menu-toggle" to="/badge">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">{t("menu_badge")}</span>
                </NavLink>
              </li>
              <li
                className={`menu-item menu-item-submenu ${getMenuItemActive(
                  "/splash",
                  true
                )}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink className="menu-link menu-toggle" to="/splash">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">{"Splash"}</span>
                </NavLink>
              </li>
            </ul>
          </div>
        </li>

        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "/google-material",
            true
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/google-material">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/General/User.svg")} />
            </span>
            <span className="menu-text">{t("menu_user")}</span>
            <i className="menu-arrow" />
          </NavLink>

          <div className="menu-submenu ">
            <ul className="menu-subnav">
              <li
                className={`menu-item menu-item-submenu ${getMenuItemActive(
                  "user-management/members",
                  true
                )}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink
                  className="menu-link menu-toggle"
                  to="/user-management/members"
                >
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">{t("menu_active_user")}</span>
                </NavLink>
              </li>
              <li
                className={`menu-item menu-item-submenu ${getMenuItemActive(
                  "user-management/inactive-members",
                  true
                )}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink
                  className="menu-link menu-toggle"
                  to="/user-management/inactive-members"
                >
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">{t("user_inactive_user")}</span>
                </NavLink>
              </li>
              <li
                className={`menu-item menu-item-submenu ${getMenuItemActive(
                  "/user/block",
                  true
                )}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink className="menu-link menu-toggle" to="/user/block">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">{t("user_block_user")}</span>
                </NavLink>
              </li>
              {permissionGuestList && (
                <li
                  className={`menu-item menu-item-submenu ${getMenuItemActive(
                    "user-management/guest",
                    true
                  )}`}
                  aria-haspopup="true"
                  data-menu-toggle="hover"
                >
                  <NavLink
                    className="menu-link menu-toggle"
                    to="/user-management/guest"
                  >
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">{t("user_guest")}</span>
                  </NavLink>
                </li>
              )}
            </ul>
          </div>
        </li>

        {/* ad campaign */}
        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "/ad-campaign",
            true
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/ad-campaign">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/General/User.svg")} />
            </span>
            <span className="menu-text">{"Ad Campaign"}</span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu">
            <ul className="menu-subnav">
              <li
                className={`menu-item menu-item-submenu ${getMenuItemActive(
                  "/advertiser-list",
                  true
                )}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink
                  className="menu-link menu-toggle"
                  to="/advertiser-list"
                >
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">{"Advertiser List"}</span>
                </NavLink>
              </li>
 

              <li
                className={`menu-item menu-item-submenu ${getMenuItemActive(
                  "/advertiser-notice",
                  true
                )}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink
                  className="menu-link menu-toggle"
                  to="/advertiser-notice"
                >
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">{"Advertiser Notice"}</span>
                </NavLink>
              </li>

              <li
                className={`menu-item menu-item-submenu ${getMenuItemActive(
                  "/advertiser-qna",
                  true
                )}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink className="menu-link menu-toggle" to="/advertiser-qna">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">{"Advertiser Q&A"}</span>
                </NavLink>
              </li>

              <li
                className={`menu-item menu-item-submenu ${getMenuItemActive(
                  "/advertiser-service-guide",
                  true
                )}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink
                  className="menu-link menu-toggle"
                  to="/advertiser-service-guide"
                >
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">
                    {"Advertiser Service Guide"}
                  </span>
                </NavLink>
              </li>

              <li
                className={`menu-item menu-item-submenu ${getMenuItemActive(
                  "/advertiser-creator-list",
                  true
                )}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink
                  className="menu-link menu-toggle"
                  to="/advertiser-creator-list"
                >
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">{"User Creator List"}</span>
                </NavLink>
              </li>

              <li
                className={`menu-item menu-item-submenu ${getMenuItemActive(
                  "/advertiser-campaign-list",
                  true
                )}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink
                  className="menu-link menu-toggle"
                  to="/advertiser-campaign-list"
                >
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">{"Campaign List"}</span>
                </NavLink>
              </li>

              <li
                className={`menu-item menu-item-submenu ${getMenuItemActive(
                  "/chat-report-list",
                  true
                )}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink
                  className="menu-link menu-toggle"
                  to="/chat-report-list"
                >
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">{"Chat Report List"}</span>
                </NavLink>
              </li>
            </ul>
          </div>
        </li>
        {/* ad campaign */}

        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "/google-material",
            true
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/google-material">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/General/Other1.svg")} />
            </span>
            <span className="menu-text">{t("menu_content")}</span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu ">
            <ul className="menu-subnav">
              {permissionCreatorList && (
                <li
                  className={`menu-item menu-item-submenu ${getMenuItemActive(
                    "/creator",
                    true
                  )}`}
                  aria-haspopup="true"
                  data-menu-toggle="hover"
                >
                  <NavLink className="menu-link menu-toggle" to="/creator">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">{t("menu_creator")}</span>
                  </NavLink>
                </li>
              )}
              {permissionVideoList && (
                <li
                  className={`menu-item menu-item-submenu ${getMenuItemActive(
                    "/video",
                    true
                  )}`}
                  aria-haspopup="true"
                  data-menu-toggle="hover"
                >
                  <NavLink className="menu-link menu-toggle" to="/video">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">{t("menu_video")}</span>
                  </NavLink>
                </li>
              )}

              <li
                className={`menu-item menu-item-submenu ${getMenuItemActive(
                  "/ingredient",
                  true
                )}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink className="menu-link menu-toggle" to="/ingredient">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">
                    {t("ingredient_ingredient")}
                  </span>
                </NavLink>
              </li>
              <li
                className={`menu-item menu-item-submenu ${getMenuItemActive(
                  "/group-ingredient",
                  true
                )}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink
                  className="menu-link menu-toggle"
                  to="/group-ingredient"
                >
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">
                    {t("group_group_ingredient")}
                  </span>
                </NavLink>
              </li>
              {permissionCategoryList && (
                <li
                  className={`menu-item menu-item-submenu ${getMenuItemActive(
                    "/category",
                    true
                  )}`}
                  aria-haspopup="true"
                  data-menu-toggle="hover"
                >
                  <NavLink className="menu-link menu-toggle" to="/category">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">{t("menu_category")}</span>
                  </NavLink>
                </li>
              )}
              {permissionTagList && (
                <li
                  className={`menu-item menu-item-submenu ${getMenuItemActive(
                    "/tag",
                    true
                  )}`}
                  aria-haspopup="true"
                  data-menu-toggle="hover"
                >
                  <NavLink className="menu-link menu-toggle" to="/tag">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">{t("menu_tag")}</span>
                  </NavLink>
                </li>
              )}
              <li
                className={`menu-item menu-item-submenu ${getMenuItemActive(
                  "/blog",
                  true
                )}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink className="menu-link menu-toggle" to="/blog">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">{t("blog_blog")}</span>
                </NavLink>
              </li>
              <li
                className={`menu-item menu-item-submenu ${getMenuItemActive(
                  "/creatorblog",
                  true
                )}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink className="menu-link menu-toggle" to="/creatorblog">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">{t("cb_creator_blog")}</span>
                </NavLink>
              </li>
            </ul>
          </div>
        </li>
        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "/product",
            true
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/product">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Files/File.svg")} />
            </span>
            <span className="menu-text">{t("product")}</span>
          </NavLink>
        </li>
        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "/google-material",
            true
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/google-material">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Devices/Display1.svg")}
              />
            </span>
            <span className="menu-text">{t("menu_main_display")}</span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu ">
            <ul className="menu-subnav">
              <li
                className={`menu-item menu-item-submenu ${getMenuItemActive(
                  "/weekly",
                  true
                )}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink className="menu-link menu-toggle" to="/weekly">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">{t("menu_weekly")}</span>
                </NavLink>
              </li>
              <li
                className={`menu-item menu-item-submenu ${getMenuItemActive(
                  "/curated",
                  true
                )}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink className="menu-link menu-toggle" to="/curated">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Curated Video</span>
                </NavLink>
              </li>
              <li
                className={`menu-item menu-item-submenu ${getMenuItemActive(
                  "/post-curated",
                  true
                )}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink className="menu-link menu-toggle" to="/post-curated">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Curated Post</span>
                </NavLink>
              </li>
              <li
                className={`menu-item menu-item-submenu ${getMenuItemActive(
                  "/ingredient-curated",
                  true
                )}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink
                  className="menu-link menu-toggle"
                  to="/ingredient-curated"
                >
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Curated Ingredient</span>
                </NavLink>
              </li>

              <li
                className={`menu-item menu-item-submenu ${getMenuItemActive(
                  "/pin-management",
                  true
                )}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink className="menu-link menu-toggle" to="/pin-management">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Pin Management</span>
                </NavLink>
              </li>
            </ul>
          </div>
        </li>
        {permissionCommentList && (
          <li
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              "/comment",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink className="menu-link menu-toggle" to="/comment">
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Cap-2.svg")} />
              </span>
              <span className="menu-text">{t("cmt_user_contents")}</span>
              <i className="menu-arrow" />
            </NavLink>
            <div className="menu-submenu ">
              <ul className="menu-subnav">
                <li
                  className={`menu-item menu-item-submenu ${getMenuItemActive(
                    "/comment/feed",
                    true
                  )}`}
                  aria-haspopup="true"
                  data-menu-toggle="hover"
                >
                  <NavLink className="menu-link menu-toggle" to="/comment/feed">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">{t("cmt_feed")}</span>
                  </NavLink>
                </li>
                <li
                  className={`menu-item menu-item-submenu ${getMenuItemActive(
                    "/comment/reply",
                    true
                  )}`}
                  aria-haspopup="true"
                  data-menu-toggle="hover"
                >
                  <NavLink
                    className="menu-link menu-toggle"
                    to="/comment/reply"
                  >
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">{t("cmt_feed_comment")}</span>
                  </NavLink>
                </li>
                <li
                  className={`menu-item menu-item-submenu ${getMenuItemActive(
                    "/comment/poll-comment",
                    true
                  )}`}
                  aria-haspopup="true"
                  data-menu-toggle="hover"
                >
                  <NavLink
                    className="menu-link menu-toggle"
                    to="/comment/poll-comment"
                  >
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">{t("cmt_poll_comment")}</span>
                  </NavLink>
                </li>
                <li
                  className={`menu-item menu-item-submenu ${getMenuItemActive(
                    "/comment/newsletter-comment",
                    true
                  )}`}
                  aria-haspopup="true"
                  data-menu-toggle="hover"
                >
                  <NavLink
                    className="menu-link menu-toggle"
                    to="/comment/newsletter-comment"
                  >
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">
                      {t("cmt_newsletter_comment")}
                    </span>
                  </NavLink>
                </li>
              </ul>
            </div>
          </li>
        )}
        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "/google-material",
            true
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/google-material">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/General/Settings-1.svg")}
              />
            </span>
            <span className="menu-text">{t("system")}</span>

            <i className="menu-arrow" />
          </NavLink>

          <div className="menu-submenu ">
            <ul className="menu-subnav">
              <li
                className={`menu-item menu-item-submenu ${getMenuItemActive(
                  "/term_condition",
                  true
                )}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink className="menu-link menu-toggle" to="/term_condition">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">{t("menu_term_condition")}</span>
                </NavLink>
              </li>

              <li
                className={`menu-item menu-item-submenu ${getMenuItemActive(
                  "/app-version",
                  true
                )}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink className="menu-link menu-toggle" to="/app-version">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">{t("system_app_version")}</span>
                </NavLink>
              </li>
            </ul>
          </div>
        </li>
        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "/google-material",
            true
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/google-material">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Shopping/Chart-bar1.svg")}
              />
            </span>

            <span className="menu-text">{t("menu_statistics")}</span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu ">
            <ul className="menu-subnav">
              <li
                className={`menu-item menu-item-submenu ${getMenuItemActive(
                  "/statistic/content",
                  true
                )}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink
                  className="menu-link menu-toggle"
                  to="/statistic/content"
                >
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">{t("menu_content")}</span>
                </NavLink>
              </li>
              <li
                className={`menu-item menu-item-submenu ${getMenuItemActive(
                  "/statistic/video",
                  true
                )}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink
                  className="menu-link menu-toggle"
                  to="/statistic/video"
                >
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">{t("menu_video")}</span>
                </NavLink>
              </li>
              <li
                className={`menu-item menu-item-submenu ${getMenuItemActive(
                  "/statistic/creator",
                  true
                )}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink
                  className="menu-link menu-toggle"
                  to="/statistic/creator"
                >
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">{t("menu_creator")}</span>
                </NavLink>
              </li>
              <li
                className={`menu-item menu-item-submenu ${getMenuItemActive(
                  "/statistic/tag",
                  true
                )}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink className="menu-link menu-toggle" to="/statistic/tag">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">{t("menu_tag")}</span>
                </NavLink>
              </li>
              <li
                className={`menu-item menu-item-submenu ${getMenuItemActive(
                  "/statistic/member",
                  true
                )}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink
                  className="menu-link menu-toggle"
                  to="/statistic/member"
                >
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">{t("menu_member")}</span>
                </NavLink>
              </li>
            </ul>
          </div>
        </li>
      </ul>

      {/* end::Menu Nav */}
    </>
  );
}
