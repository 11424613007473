import { toastReturnAPI, toastReturnAPIError } from "app/modules/utils/helper";
import * as requestFromServer from "./usersCrud";
import { callTypes, usersSlice } from "./usersSlice";
import {getInactiveMembers} from "./usersCrud";

const { actions } = usersSlice;

export const fetchAdminGroups = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getAllAdminGroups(queryParams)
    .then((response) => {
      dispatch(actions.groupsFetched(response));
    })
    .catch((error) => {
      error.clientMessage = "Can't find users";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};
export const fetchAdminHistories = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getAllAdminHistories(queryParams)
    .then((response) => {
      dispatch(actions.endCall());
      return response;
    })
    .catch((error) => {
      error.clientMessage = "Can't find users";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};
export const fetchBadge = () => (dispatch) => {
  return requestFromServer
    .getAllBadge()
    .then((response) => {
      dispatch(actions.endCall());
      return response;
    })
    .catch((error) => {
      error.clientMessage = "Can't find users";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};
export const fetchAdminGroup = (id) => (dispatch) => {
  if (!id) {
    return dispatch(actions.groupFetched());
  }
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getAdminGroupById(id)
    .then((response) => {
      dispatch(actions.groupFetched(response));
    })
    .catch((error) => {
      error.clientMessage = "Can't find user";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const fetchPermission = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getPermission(queryParams)
    .then((response) => {
      dispatch(actions.endCall());
      return response;
    })
    .catch((error) => {
      error.clientMessage = "Can't find users";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const createAdminGroup = (userForCreation) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createAdminGroup(userForCreation)
    .then((response) => {
      dispatch(actions.endCall());
      toastReturnAPI(response.message);
      return response;
    })
    .catch((error) => {
      error.clientMessage = "Can't create user";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      toastReturnAPIError(error.messages[0].message);
      return error.messages[0];
    });
};
export const updateBadge = (user) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateBadge(user)
    .then((response) => {
      toastReturnAPI(response.message);
      return response;
    })
    .catch((error) => {
      error.clientMessage = "Can't update user";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      toastReturnAPIError(error.messages[0].message);
      return error.messages[0];
    });
};
export const updateAdminGroup = (user) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateAdminGroup(user)
    .then((response) => {
      dispatch(actions.userUpdated({ user }));
      toastReturnAPI(response.message);
      return response;
    })
    .catch((error) => {
      error.clientMessage = "Can't update user";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      toastReturnAPIError(error.messages[0].message);
      return error.messages[0];
    });
};

export const updateUserBlock = (user) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateUserBlock(user)
    .then((response) => {
      toastReturnAPI(response.message);
      return response;
    })
    .catch((error) => {
      error.clientMessage = "Can't update user";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      toastReturnAPIError(error.messages[0].message);
      return error.messages[0];
    });
};

export const deleteAdminGroup = (id) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteAdminGroup(id)
    .then((response) => {
      dispatch(actions.userDeleted({ id }));
    })
    .catch((error) => {
      error.clientMessage = "Can't delete user";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const fetchAdmins = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getAllAdmins(queryParams)
    .then((response) => {
      dispatch(actions.usersFetched(response));
    })
    .catch((error) => {
      error.clientMessage = "Can't find users";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};
export const fetchInactiveAdmins = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getAllInactiveAdmins(queryParams)
    .then((response) => {
      dispatch(actions.usersFetched(response));
    })
    .catch((error) => {
      error.clientMessage = "Can't find users";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};
export const createAdmin = (userForCreation) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createAdmin(userForCreation)
    .then((response) => {
      const user = response;
      dispatch(actions.userCreated({ user }));
      toastReturnAPI(response.message);
      return response;
    })
    .catch((error) => {
      error.clientMessage = "Can't create user";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      toastReturnAPIError(error.messages[0].message);
      return error.messages[0];
    });
};

export const updateAdmin = (user) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateAdmin(user)
    .then((response) => {
      toastReturnAPI(response.message);
      dispatch(actions.userUpdated({ user }));
      return response;
    })
    .catch((error) => {
      error.clientMessage = "Can't update user";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const unlockAdmin = (body) => (dispatch) => {
  return requestFromServer
    .unlockBlock(body)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      error.clientMessage = "Can't update user";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteAdmin = (id) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteAdmin(id)
    .then((response) => {
      dispatch(actions.userDeleted({ id }));
      toastReturnAPI(response.message);
    })
    .catch((error) => {
      error.clientMessage = "Can't delete user";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      toastReturnAPIError(error.messages[0].message);
    });
};

export const fetchGroups = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getAllAdminGroups(queryParams)
    .then((response) => {
      dispatch(actions.groupsFetched(response));
    })
    .catch((error) => {
      error.clientMessage = "Can't find users";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchMembers = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getAllMembers({ ...queryParams, pageSize: null })
    .then((response) => {
      dispatch(actions.usersFetched(response));
      return response;
    })
    .catch((error) => {
      error.clientMessage = "Can't find users";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};
export const fetchInactiveMembers = (queryParams) => (dispatch) => {
    dispatch(actions.startCall({ callType: callTypes.list }));
    return requestFromServer
        .getInactiveMembers({ ...queryParams, pageSize: null })
        .then((response) => {
            dispatch(actions.usersFetched(response));
            return response;
        })
        .catch((error) => {
            error.clientMessage = "Can't find users";
            dispatch(actions.catchError({ error, callType: callTypes.list }));
        });
};
export const fetchMembers1 = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getAllMembers({ ...queryParams, pageSize: null })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      error.clientMessage = "Can't find users";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};
export const fetchGuests = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getAllGuests({ ...queryParams, pageSize: null })
    .then((response) => {
      dispatch(actions.usersFetched(response));
      return response;
    })
    .catch((error) => {
      error.clientMessage = "Can't find users";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};
export const fetchQuestion = (id) => {
  return requestFromServer.getQuestionById(id).then((response) => {
    return response;
  });
};

export const fetchMember = (id) => (dispatch) => {
  if (!id) {
    return dispatch(actions.userFetched({ userForEdit: undefined }));
  }
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getMemberById(id)
    .then((response) => {
      dispatch(actions.userFetched(response));
    })
    .catch((error) => {
      error.clientMessage = "Can't find user";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
export const fetchUser = (id) => (dispatch) => {
  if (!id) {
    return dispatch(actions.userFetched({ userForEdit: undefined }));
  }
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getAdminById(id)
    .then((response) => {
      dispatch(actions.userFetched(response));
    })
    .catch((error) => {
      error.clientMessage = "Can't find user";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const fetchVideosByUser = (id) => (dispatch) => {
  if (!id) {
    return dispatch(actions.userFetched({ userForEdit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getVideosByUser(id)
    .then((response) => {
      dispatch(actions.endCall());
      return response;
    })
    .catch((error) => {
      error.clientMessage = "Can't find user";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteUser = (id) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteUser(id)
    .then((response) => {
      dispatch(actions.userDeleted({ id }));
    })
    .catch((error) => {
      error.clientMessage = "Can't delete user";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateUser = (user) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateUser(user)
    .then((response) => {
      toastReturnAPI(response.message);
      dispatch(actions.userUpdated({ user }));
      return response;
    })
    .catch((error) => {
      error.clientMessage = "Can't update user";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      toastReturnAPIError(error.messages[0].message);
      return error.messages[0];
    });
};

export const updateUsersStatus = (ids, status) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateStatusForUsers(ids, status)
    .then((response) => {
      toastReturnAPI(response.message);
      dispatch(actions.usersStatusUpdated({ ids, status }));
      return response;
    })
    .catch((error) => {
      error.clientMessage = "Can't update users status";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      toastReturnAPIError(error.messages[0].message);
      return error.messages[0];
    });
};

export const deleteUsers = (ids) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteUsers(ids)
    .then(() => {
      dispatch(actions.usersDeleted({ ids }));
    })
    .catch((error) => {
      error.clientMessage = "Can't delete users";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
