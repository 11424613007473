import { createAsyncThunk } from '@reduxjs/toolkit';
import { parseErrorToHandle } from 'app/modules/utils/requestUtils';

import * as requestFromServer from './introCardCrud';

export const fetchIntro = createAsyncThunk('intro/fetchIntro', async (id, { rejectWithValue }) => {
  try {
    return requestFromServer.getIntroById();
  } catch (error) {
    return rejectWithValue(parseErrorToHandle(error));
  }
});

export const createIntro = createAsyncThunk(
  'intro/createIntro',
  async (introForCreation, { rejectWithValue }) => {
    try {
      return requestFromServer.createIntro(introForCreation);
    } catch (error) {
      return rejectWithValue(parseErrorToHandle(error));
    }
  }
);

export const updateIntro = createAsyncThunk(
  'intro/updateIntro',
  async (body, { rejectWithValue }) => {
    try {
      return requestFromServer.updateIntro(body);
    } catch (error) {
      return rejectWithValue(parseErrorToHandle(error));
    }
  }
);

export const uploadFileIntro = createAsyncThunk(
  'intro/uploadFile',
  async (dataFileUpload, { rejectWithValue }) => {
    try {
      const res = requestFromServer.uploadFile(dataFileUpload);
      return res;
    } catch (error) {
      return rejectWithValue(parseErrorToHandle(error));
    }
  }
);
