import { createSlice } from "@reduxjs/toolkit";

const initialBlogState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  entitiesPreview: null,
  blogForEdit: undefined,
  lastError: null,
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const blogSlice = createSlice({
  name: "Blog",
  initialState: initialBlogState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    endCall: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.listLoading = false;
    },
    // get blog ById
    blogFetched: (state, action) => {
      state.actionsLoading = false;
      state.blogForEdit = action.payload?.blogForEdit?.data;
      state.error = null;
      state.actionsLoading = false;
    },
    // find blog
    blogsFetched: (state, action) => {
      const entities = action.payload.data;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = action.payload.pagination.count;
      state.actionsLoading = false;
    },
    //
    blogsPreviewFetched: (state, action) => {
      const { entities } = action.payload;
      state.listLoading = false;
      state.actionsLoading = false;
      state.error = null;
      state.entitiesPreview = entities;
    },
    // create blog
    blogCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.blog);
    },
    // update blog
    blogUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map((entity) => {
        if (entity.id === action.payload.blog.id) {
          return action.payload.blog;
        }
        return entity;
      });
    },
    // delete blog
    blogDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => el.id !== action.payload.id
      );
    },
    // delete blog
    blogsDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => !action.payload.ids.includes(el.id)
      );
    },
    // blogUpdateState
    blogStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { ids, status } = action.payload;
      state.entities = state.entities.map((entity) => {
        if (ids.findIndex((id) => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    },
  },
});
