import axios from 'axios';
import { parseError, parseBody, parseQueryParams } from './requestUtils';
import store from '../../../redux/store';
import * as auth from '../Auth/_redux/authRedux';
import { get } from 'lodash';
const API_URL = `${process.env.REACT_APP_API_URL}`;

class ApiService {
  constructor() {
    if (!ApiService.instance) {
      this.client = axios.create({
        baseURL: API_URL,
        timeout: process.env.TIME_OUT || 30000,
        headers: {
          'Content-Type': 'application/json;charset=utf-8'
        }
      });

      this.client.interceptors.request.use(
        config => {
          if (config.method === 'get') {
            config.params = parseQueryParams(config.params);
          }
          const state = store.getState();
          const authToken = state.auth.authToken;
          if (authToken && config?.url !== '/admin-users/login') {
            config.headers['Authorization'] = `Bearer ${authToken}`;
          }
          return config;
        },
        error => {
          Promise.reject(error);
        }
      );

      this.client.interceptors.response.use(
        response => {
          return parseBody(response);
        },
        error => {
          if (error.response) {
            if (get(error, 'response.data.data[0].messages[0].id') === 'token_revoked') {
              const { dispatch } = store;
              dispatch(auth.actions.forceLogout());
            }

            return parseError(error.response.data);
          } else {
            return Promise.reject(error);
          }
        }
      );
      this.clientUpload = axios.create({
        baseURL: API_URL,
        timeout: process.env.TIME_OUT || 30000,
        headers: {
          'Content-Type': 'multipart/form-data',
          Accept: '*/*'
        }
      });

      this.clientUpload.interceptors.request.use(
        config => {
          const state = store.getState();
          const authToken = state.auth.authToken;
          if (authToken) {
            config.headers['Authorization'] = `Bearer ${authToken}`;
          }
          return config;
        },
        error => {
          Promise.reject(error);
        }
      );

      this.clientUpload.interceptors.response.use(
        response => {
          return parseBody(response);
        },
        error => {
          if (error.response) {
            return parseError(error.response.data);
          } else {
            return Promise.reject(error);
          }
        }
      );
    }

    return ApiService.instance;
  }

  get(url, data) {
    return this.client.get(url, { params: data });
  }
  post(url, data) {
    return this.client.post(url, data);
  }
  put(url, data) {
    return this.client.put(url, data);
  }
  patch(url, data) {
    return this.client.patch(url, data);
  }
  delete(url, data) {
    return this.client.delete(url, { data });
  }
  upload(url, data) {
    return this.clientUpload.post(url, data);
  }
}
const instance = new ApiService();
Object.freeze(instance);
export default instance;
