import axios from "axios";
import ApiService from "app/modules/utils/ApiService";
import ApiServiceNoToken from "app/modules/utils/ApiServiceNoToken";
// export const LOGIN_URL = `/auth/local`;
export const LOGIN_URL = `/admin-users/login`;
// export const ME_URL = `/users/me`;
export const ME_URL = `/admin-users/me`;

export const REGISTER_URL = "api/auth/register";
export const REQUEST_PASSWORD_URL = "api/auth/forgot-password";
export const RESET_PW = "/admin-users/reset-password";
export const CHANGE_PW = "/admin-users/change-password";
export const UNLOCK_ACC = "/admin-users/unlock-account";
export const ADMIN_GROUP_URL = "/admin-groups";
export const LOGOUT = "/admin-users/logout";
export const USER_ADMIN = "/admin-users";

export function login(email, password, code) {
  let user;
  if (code) {
    user = { identifier: email, password, code };
  } else {
    user = { identifier: email, password };
  }
  return ApiServiceNoToken.post(LOGIN_URL, user).then((response) => {
    return response;
  });
}

export function newQrTotp(token) {
  return ApiServiceNoToken.post(`${USER_ADMIN}/new-qr-totp`, token).then(
    (response) => {
      return response;
    }
  );
}
export function linkCodeTotp(body) {
  return ApiServiceNoToken.post(`${USER_ADMIN}/link-code-totp`, body).then(
    (response) => {
      return response;
    }
  );
}
export function register(email, fullname, username, password) {
  return axios.post(REGISTER_URL, { email, fullname, username, password });
}

export function loginStep2(body) {
  return ApiServiceNoToken.post(`${USER_ADMIN}/login-step2-totp`, body).then(
    (res) => {
      return res;
    }
  );
}
export function sendOTP(body) {
  return ApiServiceNoToken.post(`${USER_ADMIN}/send-otp`, body);
}
export function requestPassword(email) {
  return axios.post(REQUEST_PASSWORD_URL, { email });
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  return ApiService.get(ME_URL);
}

export function getUserGroupById(id) {
  // Authorization head should be fulfilled in interceptor.
  return ApiService.get(`${ADMIN_GROUP_URL}/${id}`);
}

export function resetPw(email) {
  const resetParam = { email };
  return ApiServiceNoToken.post(RESET_PW, resetParam).then((response) => {
    return response;
  });
}

export function changePw(password, confirmPassword, code) {
  const changePwParam = {
    password,
    passwordConfirmation: confirmPassword,
    code,
  };

  return ApiServiceNoToken.post(CHANGE_PW, changePwParam).then((response) => {
    return response;
  });
}

export function unlockAcc(email) {
  const unlocklParam = { email };
  return ApiService.post(UNLOCK_ACC, unlocklParam).then((response) => {
    return response;
  });
}

export function logout() {
  return ApiService.post(LOGOUT).then((response) => {
    return response;
  });
}
