import { createSlice } from "@reduxjs/toolkit";

const initialCuratedIngredientState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  entitiesPreview: null,
  curatedIngredientForEdit: undefined,
  lastError: null,
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const curatedIngredientSlice = createSlice({
  name: "CuratedIngredient",
  initialState: initialCuratedIngredientState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    endCall: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.listLoading = false;
    },
    // get curatedIngredient ById
    curatedIngredientFetched: (state, action) => {
      state.actionsLoading = false;
      state.curatedIngredientForEdit = action.payload.curatedIngredientForEdit;
      state.error = null;
      state.actionsLoading = false;
    },
    // find curatedIngredient
    curatedIngredientsFetched: (state, action) => {
      const entities = action.payload.data;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = action.payload.pagination.total_count;
      state.actionsLoading = false;
    },
    //
    curatedIngredientsPreviewFetched: (state, action) => {
      const { entities } = action.payload;
      state.listLoading = false;
      state.actionsLoading = false;
      state.error = null;
      state.entitiesPreview = entities;
    },
    // create curatedIngredient
    curatedIngredientCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.curatedIngredient);
    },
    // update curatedIngredient
    curatedIngredientUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map((entity) => {
        if (entity.id === action.payload.curatedIngredient.id) {
          return action.payload.curatedIngredient;
        }
        return entity;
      });
    },
    // delete curatedIngredient
    curatedIngredientDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => el.id !== action.payload.id
      );
    },
    // delete curatedIngredient
    curatedIngredientsDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => !action.payload.ids.includes(el.id)
      );
    },
    // curatedIngredientUpdateState
    curatedIngredientStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { ids, status } = action.payload;
      state.entities = state.entities.map((entity) => {
        if (ids.findIndex((id) => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    },
  },
});
