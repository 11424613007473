import ApiService from "../../utils/ApiService";
export const BANNER_URL = `${process.env.REACT_APP_API_URL}/banners`;
export const UPLOAD_URL = `${process.env.REACT_APP_API_URL}/upload`;

export function uploadFile(formData) {
  return ApiService.post(UPLOAD_URL, formData);
}

// CREATE =>  POST: add a new Banner to the server
export function createBanner(banner) {
  return ApiService.post(BANNER_URL, banner);
}

export function getYoutube(id) {
  return ApiService.get(`${BANNER_URL}/youtube/${id}`);
}

export function updateBannersBulk(body) {
  return ApiService.post(`${BANNER_URL}/bulkupdate`, body);
}
export function getBannerById(bannerId) {
  return ApiService.get(`${BANNER_URL}/${bannerId}`);
}
export function updateInspection(body) {
  return ApiService.post(`${BANNER_URL}/inspection/${body.type}`, body);
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findBanners(queryParams) {
  return ApiService.get(`${BANNER_URL}`, queryParams);
}
export function findBannersPreview() {
  return ApiService.get(
    `${BANNER_URL}/?_sort=sorting:asc&_where[sorting_gte]=0`
  );
}
// UPDATE => PUT: update the Banner on the server
export function updateBanner({ id, body }) {
  return ApiService.put(`${BANNER_URL}/${id}`, body);
}

// UPDATE Status
export function updateStatusBanners(ids, status) {
  return ApiService.post(`${BANNER_URL}/updateStatusForBanners`, {
    ids,
    status,
  });
}

// DELETE => delete the Banner from the server
export function deleteBanner(bannerId) {
  return ApiService.delete(`${BANNER_URL}/${bannerId}`);
}

// DELETE Banners by ids
export function deleteBanners(body) {
  return ApiService.post(`${BANNER_URL}/bulkdelete`, body);
}
