import React from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Input, SelectField, PhoneInputHook } from "components/controls";
import { useTranslation } from "react-i18next";

export function UserEditForm({
  typePassword,
  saveUser,
  user,
  actionsLoading,
  onHide,
  groups,
  id,
  unlockUser,
}) {
  const { t } = useTranslation();

  // Validation schema
  const userEditSchemaAdd = Yup.object().shape({
    group_id: Yup.string().required(`${t("admin_admin_group")} ${t("common_is_a_required_field")}`),
    name: Yup.string().required(`${t("admin_name")} ${t("common_is_a_required_field")}`),
    email: Yup.string().email("Invalid email").required(`${t("members_email")} ${t("common_is_a_required_field")}`),
  });

  const userEditSchemaEdit = Yup.object().shape({
    name: Yup.string().required(`${t("admin_name")} ${t("common_is_a_required_field")}`),
    email: Yup.string().email("Invalid email").required(`${t("members_email")} ${t("common_is_a_required_field")}`),

    password: Yup.string()
        .min(8)
        .max(16)
        .matches(
            // eslint-disable-next-line no-useless-escape
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])/,
            "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
        ),
  });

  const cloneUser = { ...user, password: "" };

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={cloneUser}
        validationSchema={id ? userEditSchemaEdit : userEditSchemaAdd}
        onSubmit={(values) => {
          saveUser(values);
        }}
      >
        {({ handleSubmit }) => (
          <>
            <Modal.Body className="overlay overlay-block cursor-default">
              {actionsLoading && (
                <div className="overlay-layer bg-transparent">
                  <div className="spinner spinner-lg spinner-success" />
                </div>
              )}
              <Form className="form form-label-right">
                {!typePassword && (
                  <div className=" row">
                    <div className="col-lg-6">
                      <Field
                        name="group_id"
                        component={SelectField}
                        placeholder={t("placeholder_select")}
                        options={groups || []}
                        label={t("admin_admin_group")}
                        keyLabel="name"
                        keyValue="id"
                      />
                    </div>
                    {cloneUser?.dbstatus === 3 && (
                      <div className="col-lg-6 mg-t-25">
                        <b>Status:Lock</b>
                      </div>
                    )}
                  </div>
                )}
                {!typePassword && (
                  <div className="row">
                    <div className="col-lg-6">
                      <Field
                        name="name"
                        component={Input}
                        placeholder={t("admin_name")}
                        label={t("admin_name")}
                        readOnly={id}
                      />
                    </div>
                    <div className="col-lg-6">
                      <Field
                        type="email"
                        name="email"
                        component={Input}
                        placeholder={t("members_email")}
                        label={t("members_email")}
                        readOnly={id}
                      />
                    </div>
                  </div>
                )}
                {!typePassword && (
                  <div className=" row">
                    <div className="col-lg-6">
                      <Field
                        name="cell_phone"
                        component={PhoneInputHook}
                        placeholder={t("admin_office_phone")}
                        label={t("admin_office_phone")}
                        type="text"
                      />
                    </div>
                    <div className="col-lg-6">
                      <Field
                        name="office_phone"
                        component={PhoneInputHook}
                        placeholder={t("admin_cell_phone")}
                        label={t("admin_cell_phone")}
                        type="text"
                      />
                    </div>
                  </div>
                )}
              </Form>
            </Modal.Body>
            <Modal.Footer>
              {cloneUser?.dbstatus !== 3 && (
                <button
                  type="submit"
                  onClick={handleSubmit}
                  className="btn btn-primary btn-elevate"
                >
                  {t("popup_confirm")}
                </button>
              )}

              <button
                type="button"
                onClick={onHide}
                className="btn btn-light btn-elevate"
              >
                {t("button_cancel")}
              </button>
            </Modal.Footer>
          </>
        )}
      </Formik>
    </>
  );
}
