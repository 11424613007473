import { createSlice } from "@reduxjs/toolkit";
import * as badgeActions from "Badge/_redux/badgeActions";

const initialBadgeState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  entitiesPreview: null,
  badgeForEdit: undefined,
  lastError: null,
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const badgeSlice = createSlice({
  name: "Badge",
  initialState: initialBadgeState,
  extraReducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
    },

    [badgeActions.fetchBadge.fulfilled]: (state, action) => {
      state.badgeForEdit = action.payload;
    },
    [badgeActions.fetchBadges.fulfilled]: (state, action) => {
      state.entities = action.payload;
      state.totalCount = action.payload?.length;
      state.badgeForEdit = null;
    },
  },
});
