import { createSlice } from "@reduxjs/toolkit";
import {
  isFulfilledAction,
  isPendingAction,
  isRejectedAction,
} from "app/modules/utils/helper";

const initialState = {
  isLoading: false,
};

const module = [
  "push/",
  "policy/",
  "dashboard/",
  "comment/",
  "banner",
  "weekly",
  "intro",
  "poll",
];

export const loadingSlice = createSlice({
  name: "loading",
  initialState,
  reducers: {
    startLoading: (state, action) => {
      state.isLoading = true;
    },
    endLoading: (state, action) => {
      state.isLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(isPendingAction(module), (state) => {
      state.isLoading = true;
    });
    builder.addMatcher(isFulfilledAction(module), (state) => {
      state.isLoading = false;
    });
    builder.addMatcher(isRejectedAction(module), (state) => {
      state.isLoading = false;
    });
  },
});

export const selectStateLoading = (state) => state.loading.isLoading;
export const { startLoading, endLoading } = loadingSlice.actions;
export default loadingSlice.reducer;
