import React, { useState } from "react";
import { useFormik } from "formik";
import { Link, useLocation, useHistory } from "react-router-dom";
import * as Yup from "yup";
import { get } from "lodash";
import { changePw, resetPw, unlockAcc } from "../_redux/authCrud";
import useNotyf from "app/modules/Toast/useNotyf";
import { useTranslation } from "react-i18next";

const initialValues = {
  email: "",
};

const initialValuesSubmitPw = {
  password: "",
  confirm_password: "",
};

function ForgotPassword(props) {
  const history = useHistory();
  const notif = useNotyf();
  const { t } = useTranslation();
  const query = new URLSearchParams(useLocation().search);
  const code = query.get("code");
  const status = query.get("status");

  const [loading, setLoading] = useState(false);

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const ForgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .email("Please enter a valid email address.")
      .required(t("login_email_is_required")),
  });

  const ForgotPasswordSubmitPwSchema = Yup.object().shape({
    password: Yup.string()
      .matches(
        "^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]{8,16}$",
        "Password is 8 to 16 digits, including uppercase, lowercase, number, special charactors."
      )
      .required(t("login_password_is_required")),
    confirm_password: Yup.string()
      .matches(
        "^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]{8,16}$",
        "Password is 8 to 16 digits, including uppercase, lowercase, number, special charactors."
      )
      .required("Confirm password is required field.")
      .oneOf([Yup.ref("password"), null], "Passwords must match"),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: ForgotPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      status === "unlock"
        ? unlockAcc(values.email)
            .then(() => notif.success("Please check your email"))
            .catch((error) => {
              const msg = get(
                error,
                "messages[0].message[0].messages[0].message"
              );
              notif.error(msg);
            })
            .finally(() => {
              setLoading(false);
              setSubmitting(false);
            })
        : resetPw(values.email)
            .then(() => notif.success("Please check your email"))
            .catch((error) => {
              const msg = get(
                error,
                "messages[0].message[0].messages[0].message"
              );
              notif.error(msg);
            })
            .finally(() => {
              setLoading(false);
              setSubmitting(false);
            });
    },
  });

  const formikSubmitPw = useFormik({
    initialValues: initialValuesSubmitPw,
    validationSchema: ForgotPasswordSubmitPwSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      changePw(values.password, values.confirm_password, code)
        .then(() => {
          notif.success("Set up password successfully");
          history.push(`/logout`);
        })
        .catch((error) => {
          const msg = get(error, "messages[0].message[0].messages[0].message");
          notif.error(msg);
        })
        .finally(() => {
          setLoading(false);
          setSubmitting(false);
        });
    },
  });

  return (
    <>
      <div className="login-form login-forgot" style={{ display: "block" }}>
        {/* submit email */}
        <div className="text-center mb-10 mb-lg-20">
          <h3 className="font-size-h1">
            {code ? "Set password" : "Forgotten Password ?"}
          </h3>
          <div className="text-muted font-weight-bold">
            {code
              ? "Please input your password"
              : "Enter your email to reset your password"}
          </div>
        </div>

        {code ? (
          <form
            onSubmit={formikSubmitPw.handleSubmit}
            className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
          >
            {formikSubmitPw.status && (
              <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                <div className="alert-text font-weight-bold">
                  {formikSubmitPw.status}
                </div>
              </div>
            )}
            <div className="form-group fv-plugins-icon-container">
              <input
                type="password"
                placeholder="Password"
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  "password"
                )}`}
                name="password"
                {...formikSubmitPw.getFieldProps("password")}
              />
              {formikSubmitPw.touched.password &&
              formikSubmitPw.errors.password ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    {formikSubmitPw.errors.password}
                  </div>
                </div>
              ) : null}
            </div>
            <div className="form-group fv-plugins-icon-container">
              <input
                type="password"
                placeholder="Confirm Password"
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  "confirm_password"
                )}`}
                name="confirm_password"
                {...formikSubmitPw.getFieldProps("confirm_password")}
              />
              {formikSubmitPw.touched.confirm_password &&
              formikSubmitPw.errors.confirm_password ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    {formikSubmitPw.errors.confirm_password}
                  </div>
                </div>
              ) : null}
            </div>

            <div className="form-group d-flex flex-wrap flex-center">
              <Link to="/logout">
                <button
                  type="button"
                  id="kt_login_forgot_cancel"
                  className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
                >
                  Cancel
                </button>
              </Link>
              <button
                id="kt_login_forgot_submit"
                type="submit"
                className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                disabled={formikSubmitPw.isSubmitting}
              >
                <span>Submit</span>
                {loading && (
                  <span className="ml-3 spinner spinner-white"></span>
                )}
              </button>
            </div>
          </form>
        ) : (
          <form
            onSubmit={formik.handleSubmit}
            className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
          >
            {formik.status && (
              <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                <div className="alert-text font-weight-bold">
                  {formik.status}
                </div>
              </div>
            )}
            <div className="form-group fv-plugins-icon-container">
              <input
                type="email"
                placeholder="Email"
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  "email"
                )}`}
                name="email"
                {...formik.getFieldProps("email")}
              />
              {formik.touched.email && formik.errors.email ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.email}</div>
                </div>
              ) : null}
            </div>

            <div className="form-group d-flex flex-wrap flex-center">
              <button
                id="kt_login_forgot_submit"
                type="submit"
                className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                disabled={formik.isSubmitting}
              >
                <span>Submit</span>
                {loading && (
                  <span className="ml-3 spinner spinner-white"></span>
                )}
              </button>
              <Link to="/auth">
                <button
                  type="button"
                  id="kt_login_forgot_cancel"
                  className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
                >
                  Cancel
                </button>
              </Link>
            </div>
          </form>
        )}
      </div>
    </>
  );
}

export default ForgotPassword;
