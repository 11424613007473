import ApiService from "../../utils/ApiService";

const URL = process.env.REACT_APP_API_URL;

export const getPins = () => {
  return ApiService.get(URL + "/admin-pin-section");
};

export const togglePin = (body) => {
  return ApiService.post(URL + "/admin-pin-section/pin", body);
};
