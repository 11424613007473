import { createSlice } from '@reduxjs/toolkit';

const initialVideoState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  entitiesPreview: null,
  videoForEdit: undefined,
  lastError: null,
  listThemes: null,
  filterVideo: {
    all: false,
    all_tags: false,
    all_steps: false,
    cooking_area: false,
    cooking_difficulty: false,
    cooking_time: false,
    main_ingredient: false,
    type_of_cooking: false,
    cooking_theme: false,
    cooking_ingredients_null: false,
    cooking_steps_null: false
  }
};
export const callTypes = {
  list: 'list',
  action: 'action'
};

export const videoSlice = createSlice({
  name: 'Video',
  initialState: initialVideoState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      state.listPreviewLoading = true;
      state.listUpdateLoading = true;
      state.listLoading = false;
      state.actionsLoading = false;
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
        state.listPreviewLoading = true;
        state.listUpdateLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    startTagCall: (state, action) => {
      state.actionsTagsLoading = true;
    },
    endCall: state => {
      state.error = null;
      state.listLoading = false;
      state.listPreviewLoading = false;
      state.listUpdateLoading = false;
      state.actionsLoading = false;
    },

    // get video ById
    videoFetched: (state, action) => {
      state.actionsLoading = false;
      state.videoForEdit = action.payload.videoForEdit;
      state.error = null;
    },
    // find video
    videosFetched: (state, action) => {
      const { data, total } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = data;
      state.totalCount = total;
      state.actionsLoading = false;
    },

    videosPreviewFetched: (state, action) => {
      const entities = action.payload;
      state.listLoading = false;
      state.actionsLoading = false;
      state.listPreviewLoading = false;
      state.error = null;
      state.entitiesPreview = entities;
    },

    videosUpdateBulk: (state, action) => {
      state.listLoading = false;
      state.actionsLoading = false;
      state.listUpdateLoading = false;
      state.error = null;
    },
    // create video
    videoCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.video);
    },
    // update video
    videoUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
    },
    // delete video
    videoDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
    },
    // delete video
    videosDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
    },
    // videoUpdateState
    videoStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
    },
    filterVideo: (state, action) => {
      state.filterVideo = action.payload;
    },
    listThemes: (state, action) => {
      state.actionsTagsLoading = false;
      state.listThemes = action.payload;
    }
  }
});
