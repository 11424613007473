export const isValid = (val) => {
  // eslint-disable-next-line no-useless-escape
  const regexp = /^\d{0,2}?\:?\d{0,2}$/;

  const [minutesStr, secondsStr] = val.split(":");

  if (!regexp.test(val)) {
    return false;
  }

  const minutes = Number(minutesStr);
  const seconds = Number(secondsStr);

  const isValidHour = (hour) =>
    Number.isInteger(hour) && hour >= 0 && hour < 100;
  const isValidSeconds = (seconds) =>
    (Number.isInteger(seconds) && minutes >= 0 && minutes < 100) ||
    Number.isNaN(seconds);

  if (!isValidHour(minutes) || !isValidSeconds(seconds)) {
    return false;
  }

  if (seconds < 10 && Number(secondsStr[0]) > 5) {
    return false;
  }

  const valArr = val.indexOf(":") !== -1 ? val.split(":") : [val];

  // check mm and HH
  if (
    valArr[0] &&
    valArr[0].length &&
    (parseInt(valArr[0], 10) < 0 || parseInt(valArr[0], 10) > 100)
  ) {
    return false;
  }

  if (
    valArr[1] &&
    valArr[1].length &&
    (parseInt(valArr[1], 10) < 0 || parseInt(valArr[1], 10) > 59)
  ) {
    return false;
  }

  return true;
};
