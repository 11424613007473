import { createSlice } from "@reduxjs/toolkit";
import { onGetPins } from "./pinActions";
import _get from "lodash/get";

const initialState = {
  listLoading: true,
  data: [],
};

export const pinSlice = createSlice({
  name: "PinManagement",
  initialState,
  extraReducers: {
    [onGetPins.pending]: (state) => {
      state.listLoading = true;
      state.data = [];
    },
    [onGetPins.fulfilled]: (state, action) => {
      state.listLoading = false;
      state.data = _get(action, ["payload", "data"], []);
    },
  },
});
