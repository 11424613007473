import ApiService from "../../utils/ApiService";

export const GROUP_URL = "user-questions-q-and-a";

// CREATE =>  POST: add a new Group to the server
export function createGroup(group) {
  return ApiService.post(GROUP_URL, group);
}

// READ
export function getAllGroups(queryParams) {
  return ApiService.get(`${GROUP_URL}`, queryParams);
}
export function getAllGroupGroups(queryParams) {
  return ApiService.get(`${GROUP_URL}/group/list`, queryParams);
}
export function getGroupById(groupId) {
  return ApiService.get(`${GROUP_URL}/${groupId}`);
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findGroups(queryParams) {
  return ApiService.post(`${GROUP_URL}`, { queryParams });
}

// UPDATE => PUT: update the Group on the server
export function updateGroup(group) {
  return ApiService.put(`${GROUP_URL}/${group.id}`, group);
}

// UPDATE Status
export function updateStatusForGroups(ids, status) {
  return ApiService.post(`${GROUP_URL}/updateStatusForGroups`, {
    ids,
    status,
  });
}

// DELETE => delete the Group from the server
export function deleteGroup(groupId) {
  return ApiService.post(`${GROUP_URL}/change-status`,{id:groupId, "status": "ANSWERED" });
}

// DELETE Groups by ids
export function deleteGroups(ids) {
  return ApiService.post(`${GROUP_URL}/deleteGroups`, { ids });
}
