import { createAsyncThunk } from "@reduxjs/toolkit";
import { parseErrorToHandle } from "app/modules/utils/requestUtils";

import {
  uploadFiles,
  createSplash,
  getSplashes,
  getSplashDetails,
  updateSplashDetails,
  deleteSplash,
  bulkDeleteSplashes,
} from "./splashCrud";

export const uploadSplashImages = createAsyncThunk(
  "splash/uploadFiles",
  async (dataFileUpload, { rejectWithValue }) => {
    try {
      const res = uploadFiles(dataFileUpload);
      return res;
    } catch (error) {
      return rejectWithValue(parseErrorToHandle(error));
    }
  }
);

export const onCreateSplash = createAsyncThunk(
  "splash/createSplash",
  async (body, { rejectWithValue }) => {
    try {
      const response = await createSplash(body);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const onGetSplashes = createAsyncThunk(
  "splash/getSplashes",
  async (params, { rejectWithValue }) => {
    try {
      const response = await getSplashes(params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const onGetDetails = createAsyncThunk(
  "splash/getSplashDetails",
  async (id, { rejectWithValue }) => {
    try {
      const response = await getSplashDetails(id);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const onUpdateSplashDetails = createAsyncThunk(
  "splash/updateSplashDetails",
  async ({ id, body }, { rejectWithValue }) => {
    try {
      const response = await updateSplashDetails(id, body);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const onDeleteSplash = createAsyncThunk(
  "splash/deleteSplash",
  async (id, { rejectWithValue }) => {
    try {
      const response = await deleteSplash(id);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const onBulkDeleteSplashes = createAsyncThunk(
  "splash/bulkDeleteSplashes",
  async (body, { rejectWithValue }) => {
    try {
      const response = await bulkDeleteSplashes(body);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
