/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import clsx from "clsx";
import { Dropdown } from "react-bootstrap";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { toAbsoluteUrl } from "../../../../_helpers";
import { useTranslation } from "react-i18next";
import { DropdownTopbarItemToggler } from "../../../../_partials/dropdowns";

const languages = [
  {
    lang: "en",
    name: "English",
    flag: toAbsoluteUrl("/media/svg/flags/226-united-states.svg"),
  },
  {
    lang: "kr",
    name: "대한민국",
    flag: toAbsoluteUrl("/media/svg/flags/094-south-korea.svg"),
  },
];

export function LanguageSelectorDropdown() {
  const { i18n } = useTranslation();
  let initLanguage = {
    lang: "kr",
    name: "대한민국",
    flag: toAbsoluteUrl("/media/svg/flags/094-south-korea.svg"),
  };
  if (i18n.language === "en") {
    initLanguage = {
      lang: "en",
      name: "English",
      flag: toAbsoluteUrl("/media/svg/flags/226-united-states.svg"),
    };
  }
  const [languageData, setLanguageData] = useState(initLanguage);
  const changeLanguage = (data) => {
    setLanguageData(data);
    i18n.changeLanguage(data.lang);
  };

  return (
    <Dropdown drop="down">
      <Dropdown.Toggle
        as={DropdownTopbarItemToggler}
        id="dropdown-toggle-my-cart"
      >
        <OverlayTrigger
          placement="bottom"
          overlay={
            <Tooltip id="language-panel-tooltip">Select Language</Tooltip>
          }
        >
          <div className="btn btn-icon btn-clean btn-dropdown btn-lg mr-1">
            <img
              className="h-25px w-25px rounded"
              src={languageData.flag}
              alt={languageData.name}
            />
          </div>
        </OverlayTrigger>
      </Dropdown.Toggle>
      <Dropdown.Menu className="p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround">
        <ul className="navi navi-hover py-4">
          {languages.map((language) => (
            <li
              key={language.lang}
              className={clsx("navi-item", {
                active: language.lang === languageData.lang,
              })}
            >
              <a
                href="#"
                onClick={() => changeLanguage(language)}
                className="navi-link"
              >
                <span className="symbol symbol-20 mr-3">
                  <img src={language.flag} alt={language.name} />
                </span>
                <span className="navi-text">{language.name}</span>
              </a>
            </li>
          ))}
        </ul>
      </Dropdown.Menu>
    </Dropdown>
  );
}
