import { createSlice } from '@reduxjs/toolkit';
import * as pollActions from './pollActions';
const initialPollState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  entitiesPreview: null,
  pollForEdit: undefined,
  lastError: null
};
export const callTypes = {
  list: 'list',
  action: 'action'
};

export const pollSlice = createSlice({
  name: 'Poll',
  initialState: initialPollState,
  extraReducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
    },

    [pollActions.fetchPoll.fulfilled]: (state, action) => {
      state.pollForEdit = action?.payload;
    },

    [pollActions.fetchPolls.fulfilled]: (state, action) => {
      const { surveys, total } = action.payload;
      state.entities = surveys;
      state.totalCount = total;
      state.pollForEdit = null;
    }
  }
});
