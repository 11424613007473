import React from "react";
import { FieldFeedbackLabel } from "./FieldFeedbackLabel";
import { get } from "lodash";
const getFieldCSSClasses = (touched, errors, withFeedbackLabel, value = "") => {
  const classes = ["form-control ht-100"];
  if (errors) {
    classes.push("is-invalid");
  }

  if (touched && !errors && withFeedbackLabel && value) {
    classes.push("is-valid");
  }

  return classes.join(" ");
};

export function Textarea({
  field, // { name, value, onChange, onBlur }
  form: { touched = {}, errors = {} }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  label,
  withFeedbackLabel = true,
  customFeedbackLabel,
  type = "text",
  labelButton1,
  labelButton2,
  onClick2,
  onClick1,
  isButton1 = false,
  isButton2 = false,
  setFieldValue,
  className,
  ...props
}) {
  let cloneField = { ...field };
  if (!cloneField?.value) {
    cloneField = { ...cloneField, value: "" };
  }
  const inputError = get(errors, field.name);
  return (
    <div className={`mg-b-10 ${className}`}>
      {label && (
        <label className="font-bold">
          {label} {`${withFeedbackLabel ? " (*)" : ""}`}
        </label>
      )}
      <div className="d-flex">
        <textarea
          type={type}
          className={getFieldCSSClasses(
            touched[field?.name],
            inputError,
            withFeedbackLabel
          )}
          value={cloneField.value}
          {...field}
          {...props}
        />
      </div>
      {withFeedbackLabel && (
        <FieldFeedbackLabel
          error={inputError}
          touched={touched[field.name]}
          label={label}
          type={type}
          customFeedbackLabel={customFeedbackLabel}
        />
      )}
    </div>
  );
}
