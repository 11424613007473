import { forOwn, omit, get } from "lodash";
import { DefaultErrorMsg } from "./Constant";

export function parseError(messages) {
    if (messages) {
        if (messages instanceof Array) {
            return Promise.reject({ messages: messages });
        } else {
            return Promise.reject({ messages: [messages] });
        }
    } else {
        return Promise.reject({ messages: [DefaultErrorMsg] });
    }
}

export function parseBody(response) {
    if (response.status === 200) {
        return response.data;
    } else {
        return this.parseError(response.data.messages);
    }
}

export function parseQueryParams(params) {
    forOwn(params, (value, key) => {
        if (!value) {
            params = omit(params, key);
        }
    });
    return params;
}

export function parseErrorToHandle(error) {
    return {
        message: get(error, "messages[0].message", ""),
        statusCode: get(error, "messages[0].statusCode"),
    };
}
