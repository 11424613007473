import objectPath from "object-path";

const groupingItemOnSelect = ({ ids, setIds, dataSelect, keyField }) => {
  const customerId = objectPath.get(dataSelect, keyField);
  if (ids.some((id) => id === customerId)) {
    setIds(ids.filter((id) => id !== customerId));
  } else {
    const newIds = [...ids];
    newIds.push(customerId);
    setIds(newIds);
  }
};

const onSelectAll = ({ data, setIds, entities }) => {
  if (!data) {
    setIds([]);
    return;
  }
  const newIds = entities.map((item) => item?.id);
  setIds(newIds);
};

export const getSelectRow = (props) => {
  const {
    ids = [],
    setIds,
    hideSelectAll = false,
    entities,
    keyField = "id",
    mode = "checkbox",
  } = props;
  return {
    mode,
    hideSelectAll,
    classes: "custom-class",
    selected: ids,
    clickToSelect: true,
    onSelect: (dataSelect) => {
      if (mode === "radio") {
        setIds([dataSelect[keyField]]);
        return;
      }
      groupingItemOnSelect({ dataSelect, ids, setIds, keyField });
    },
    onSelectAll: (data) => onSelectAll({ setIds, entities, data }),
  };
};
