import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import { selectStateLoading } from "./Redux/LoadingSlice";
import { RouletteSpinnerOverlay } from "react-spinner-overlay";

function Loading() {
  const loadingState = useSelector(selectStateLoading, shallowEqual);

  return (
    <RouletteSpinnerOverlay
      loading={loadingState}
      overlayColor="rgba(0,153,255,0.1)"
      color="#8950FC"
      zIndex={1500}
    />
  );
}

export default Loading;
