import { createSlice } from '@reduxjs/toolkit';

const initialUsersState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  totalCountGroups: 0,
  entities: null,
  groups: null,
  userForEdit: undefined,
  groupForEdit: undefined,
  lastError: null
};
export const callTypes = {
  list: 'list',
  action: 'action'
};

export const usersSlice = createSlice({
  name: 'Users',
  initialState: initialUsersState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },

    endCall: state => {
      state.error = null;
      state.listLoading = false;
      state.actionsLoading = false;
    },

    groupsFetched: (state, action) => {
      const { data, total } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.groups = data;
      state.totalCountGroups = total;
    },

    groupFetched: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.groupForEdit = action.payload;
    }, // getUserById
    userFetched: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.userForEdit = action.payload;
    },
    // findUsers
    usersFetched: (state, action) => {
      const { data, total } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = data;
      state.totalCount = total;
      state.userForEdit = undefined;
    },

    // createUser
    userCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
    },

    // updateUser
    userUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
    },

    // deleteUser
    userDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
    },

    // deleteUsers
    usersDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
    },

    // usersUpdateState
    usersStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
    }
  }
});
