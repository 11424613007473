import React from "react";
import TimeInput from "./TimeInput";

export function TimePickerHook({ field, placeholder, label = "" }) {
  const { name = "" } = field;
  const onTimeChangeHandler = (val) => {
    const changeEvent = {
      target: {
        name: name,
        value: val,
      },
    };
    field.onChange(changeEvent);
  };
  let cloneField = { ...field };
  if (!cloneField?.value) {
    cloneField = { ...cloneField, value: "" };
  }

  return (
    <>
      {label && <label> {label}</label>}
      <TimeInput
        className="s-input -time form-control"
        onTimeChange={onTimeChangeHandler}
        placeholder={placeholder}
        {...cloneField}
      />
    </>
  );
}
