import { createSlice } from "@reduxjs/toolkit";

const initialNotificationState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  entitiesPreview: null,
  notificationForEdit: undefined,
  lastError: null,
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const notificationSlice = createSlice({
  name: "Notification",
  initialState: initialNotificationState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listPreviewLoading = true;
        state.listUpdateLoading = true;
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
        state.listPreviewLoading = true;
        state.listUpdateLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },

    endCall: (state) => {
      state.error = null;
      state.listLoading = false;
      state.listPreviewLoading = false;
      state.listUpdateLoading = false;
      state.actionsLoading = false;
    },

    // get notification ById
    notificationFetched: (state, action) => {
      state.actionsLoading = false;
      state.notificationForEdit = action.payload.notificationForEdit;
      state.error = null;
      state.actionsLoading = false;
    },
    // find notification
    notificationsFetched: (state, action) => {
      const { notifications, total } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = notifications;
      state.totalCount = total;
      state.actionsLoading = false;
    },

    notificationsUpdateBulk: (state, action) => {
      state.listLoading = false;
      state.actionsLoading = false;
      state.listUpdateLoading = false;
      state.error = null;
    },
    // create notification
    notificationCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
    },
    // update notification
    notificationUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
    },
    // delete notification
    notificationDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
    },
    // delete notification
    notificationsDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
    },
    // notificationUpdateState
    notificationStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
    },
  },
});
