import React from "react";
import { Modal } from "react-bootstrap";

function IncorrectLoginModal({ show, onHide, count, onChangePasswordHandle }) {
  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
      className="login-page"
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">Notice</Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-align-center">
        <div className="modal-title mg-b-10">Password Error Guide</div>
        <div className="mg-b-10">Password is incorrect.</div>
        {count < 5 ? (
          <div className="mg-b-20">Check and input again</div>
        ) : (
          <div className="mg-b-20 color-red">
            Change new password via email to log in
          </div>
        )}
        <div className="color-red">
          {`(Login failed ${count < 5 ? count : 5} time)`}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div>
          {count < 5 && (
            <button
              type="button"
              onClick={onHide}
              className="btn btn-light btn-elevate mg-r-10"
            >
              Confirm
            </button>
          )}

          <button
            type="button"
            onClick={() => {
              onHide();
              onChangePasswordHandle();
            }}
            className="btn btn-primary btn-elevate"
          >
            Change Password
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export default IncorrectLoginModal;
