import { createSlice } from '@reduxjs/toolkit';
import { toastReturnAPI } from 'app/modules/utils/helper';
import * as introActions from './introActions';
const initialIntroState = {
  totalCount: 0,
  entities: null,
  introForEdit: undefined
};
export const callTypes = {
  list: 'list',
  action: 'action'
};

export const introSlice = createSlice({
  name: 'Intro',
  initialState: initialIntroState,
  extraReducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
    },

    [introActions.fetchIntro.fulfilled]: (state, action) => {
      state.introForEdit = action.payload;
    },
    [introActions.createIntro.fulfilled]: (state, action) => {
      state.introForEdit = action.payload;
      toastReturnAPI();
    },
    [introActions.updateIntro.fulfilled]: (state, action) => {
      state.introForEdit = action.payload;
      toastReturnAPI();
    }
  }
});
