import { createSlice } from '@reduxjs/toolkit';
import * as bannerActions from './bannerActions';
const initialBannerState = {
  totalCount: 0,
  entities: null,
  bannerForEdit: undefined
};
export const callTypes = {
  list: 'list',
  action: 'action'
};

export const bannerSlice = createSlice({
  name: 'Banner',
  initialState: initialBannerState,
  extraReducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
    },

    [bannerActions.fetchBanner.fulfilled]: (state, action) => {
      state.bannerForEdit = action.payload;
    },

    [bannerActions.fetchBanners.fulfilled]: (state, action) => {
      const { banners, total } = action.payload;
      state.entities = banners;
      state.totalCount = total;
      
    }
  }
});
