import React, { useState } from "react";
import { useField, useFormikContext } from "formik";
import DatePicker from "react-datepicker";
import { get } from "lodash";

const getFieldCSSClasses = (touched, errors) => {
  const classes = ["form-control"];
  if (errors) {
    classes.push("is-invalid");
  }

  if (touched && !errors) {
    classes.push("is-valid");
  }

  return classes.join(" ");
};

export function DatePickerField({ ...props }) {
  const { setFieldValue, errors, touched } = useFormikContext();
  const [field] = useField(props.field);
  const [startDate, setStartDate] = useState(new Date());
  const inputError = get(errors, field.name);
  const date = new Date();
  let currentMins = date.getMinutes();
  let currentHour = date.getHours();
  if (get(props, "showTime") === true) {
    let isSelectedDateInFuture = false;
    if (startDate) {
      isSelectedDateInFuture = startDate.getDate() !== new Date().getDate();
    }

    if (isSelectedDateInFuture) {
      currentHour = 0;
      currentMins = 0;
    }
  }
  return (
    <>
      {props.label && <label className="font-bold">{props.label}</label>}
      <DatePicker
        className={getFieldCSSClasses(touched[field.name], inputError)}
        style={{ width: "100%" }}
        {...field}
        {...props}
        selected={(field.value && new Date(field.value)) || ""}
        onChange={(val) => {
          setStartDate(val);
          setFieldValue(field.name, val);
        }}
        value={(field.value && new Date(field.value)) || ""}
        minTime={
          (get(props, "showTime") === true &&
            new Date(new Date().setHours(currentHour, currentMins, 0, 0))) ||
          ""
        }
        maxTime={
          (get(props, "showTime") === true &&
            new Date(new Date().setHours(23, 59, 0, 0))) ||
          ""
        }
        onKeyDown={(e) => {
          e.preventDefault();
        }}
      />
      {inputError && <div className="invalid-feedback">{inputError}</div>}
    </>
  );
}
