import React, { useEffect, useMemo } from "react";
import { Card } from "components/controls";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "UsersManagement/_redux/User/usersActions";
import { UserEditDialogHeader } from "./UserEditDialogHeader";
import { UserEditForm } from "./UserEditForm";
import { useUsersUIContext } from "../../UsersUIContext";

export function UserEditDialog({ id, onHide, typePassword = false }) {
  // Users UI Context
  const usersUIContext = useUsersUIContext();
  const usersUIProps = useMemo(() => {
    return {
      initUser: usersUIContext?.initUser,
    };
  }, [usersUIContext]);

  // Users Redux state
  const dispatch = useDispatch();
  const { actionsLoading, userForEdit, groups } = useSelector(
    (state) => ({
      actionsLoading: state.users.actionsLoading,
      userForEdit: state.users.userForEdit,
      groups: state.users.groups,
    }),
    shallowEqual
  );

  useEffect(() => {
    // server call for getting user by id
    dispatch(actions.fetchUser(id));
    dispatch(actions.fetchGroups());
  }, [id, dispatch]);

  // server request for saving user
  const saveUser = (user) => {
    let dataUser = {
      name: user?.name,
      email: user?.email,
      password: user?.password,
      office_phone: user?.office_phone,
      cell_phone: user?.cell_phone,
      group_id: user?.group_id.id,
      dbstatus: user?.dbstatus,
    };

    if (!id) {
      // server request for creating user
      delete dataUser.password;
      dispatch(actions.createAdmin(dataUser)).then((res) => {
        if (!res.error) onHide();
      });
    } else {
      dataUser = { ...dataUser, id: user.id };
      // server request for updating user
      dispatch(actions.updateAdmin(dataUser)).then((res) => {
        if (!res.error) onHide();
      });
    }
  };
  const unlockUser = (data) => {
    dispatch(actions.unlockAdmin(data)).then((res) => {
      if (!res?.error) onHide();
    });
  };

  return (
    <Card>
      <UserEditDialogHeader id={id} onClick={onHide} />
      <UserEditForm
        saveUser={saveUser}
        actionsLoading={actionsLoading}
        user={userForEdit || usersUIProps.initUser}
        onHide={onHide}
        groups={groups}
        id={id}
        typePassword={typePassword}
        unlockUser={unlockUser}
      />
    </Card>
  );
}
