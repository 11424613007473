import React from "react";
import {useTranslation} from "react-i18next";

function CustomTotal(start, end, total) {
    const {t} = useTranslation()
    return <span className="react-bootstrap-table-pagination-total">
        {t(`admin_showing_rows`, {start, end, total})}
  </span>
}

export default CustomTotal;
export const sizePerPageList = [
  { text: "10", value: 10 },
  { text: "30", value: 30 },
  { text: "50", value: 50 },
  { text: "100", value: 100 },
];

export const initSize = 10