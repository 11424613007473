import { createSlice } from "@reduxjs/toolkit";

const initialCuratedPostState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  entitiesPreview: null,
  curatedPostForEdit: undefined,
  lastError: null,
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const curatedPostSlice = createSlice({
  name: "CuratedPost",
  initialState: initialCuratedPostState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    endCall: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.listLoading = false;
    },
    // get curatedPost ById
    curatedPostFetched: (state, action) => {
      state.actionsLoading = false;
      state.curatedPostForEdit = action.payload.curatedPostForEdit;
      state.error = null;
      state.actionsLoading = false;
    },
    // find curatedPost
    curatedPostsFetched: (state, action) => {
      const entities = action.payload.data;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = action.payload.pagination.count;
      state.actionsLoading = false;
    },
    //
    curatedPostsPreviewFetched: (state, action) => {
      const { entities } = action.payload;
      state.listLoading = false;
      state.actionsLoading = false;
      state.error = null;
      state.entitiesPreview = entities;
    },
    // create curatedPost
    curatedPostCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.curatedPost);
    },
    // update curatedPost
    curatedPostUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map((entity) => {
        if (entity.id === action.payload.curatedPost.id) {
          return action.payload.curatedPost;
        }
        return entity;
      });
    },
    // delete curatedPost
    curatedPostDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => el.id !== action.payload.id
      );
    },
    // delete curatedPost
    curatedPostsDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => !action.payload.ids.includes(el.id)
      );
    },
    // curatedPostUpdateState
    curatedPostStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { ids, status } = action.payload;
      state.entities = state.entities.map((entity) => {
        if (ids.findIndex((id) => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    },
  },
});
